import { createStore, combineReducers } from 'redux';
import avatarReducer from './avatar/reducer';
import starReducer from './star/reducer';

const reducers = combineReducers({
    avatarReducer, starReducer
});

const store = createStore(
    reducers,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;