import React from 'react';
import { withTranslation } from 'react-i18next';
import ReactDOM from 'react-dom';

import Auth from '../../../Auth/auth';
import {connect} from 'react-redux';



class UserComponent extends React.Component {

    constructor(props){
        super(props);
        this.props = props;
		this.state = {
            currentLng: localStorage.getItem('i18nextLng'),
            menuActived: false
        }
    }


    componentWillUnmount(){
        document.removeEventListener('click', this.handleClickOutside, true);
    }

   componentDidMount(){
      document.addEventListener('click', this.handleClickOutside, true);
    }


    handleClickOutside = event => {  
      const domNode = ReactDOM.findDOMNode(this);
      if (!domNode || !domNode.contains(event.target)) {
          this.setState({menuActived: false});
      }
    }


    onhChange = (value) =>{
        if(this.props.onhChange){
            this.props.onhChange(value);
        }
    }

    onClick = (lng) => {
       this.props.i18n.changeLanguage(lng);
       this.setState({currentLng: localStorage.getItem('i18nextLng')});
       //if(this.props.reload){
        window.location = "/vod";
       //}else{
        //this.onhChange(lng);
       //}
    }
    

    logout = () =>{
        Auth.logout();
        window.location ='/login';
    }


    myaccount = () => {
        window.location = "/vod/myaccount";
    }

    favorites = () => {
        window.location = "/vod/favorites";
    }

    contact = () => {
        const path = (localStorage.getItem('i18nextLng') === "es")? 'es/contacto/' : 'en/contact/';
        window.location = "https://www.vsn-tv.com/" + path;
    }

    requestdemo = () => {
        const path = (localStorage.getItem('i18nextLng') === "es")? 'es/contacto/solicitud-demo/' : 'en/contact/demo-request/';
        window.location = "https://www.vsn-tv.com/" + path;
    }

    menu = () =>{
        this.setState({menuActived: !(this.state.menuActived)});
    }

    render(){
    

		const { currentLng } = this.state; 
        let styleAvatar  = {"backgroundImage": "url("+(this.props.avatarReducer.avatar || Auth.getImage())+")"};
		return (
                        <div className="user">
                            <div className="avatar" onClick={this.menu} style={styleAvatar} />
                            <svg onClick={this.menu} className={(this.state.menuActived)? "arrow active" : "arrow"} xmlns="http://www.w3.org/2000/svg" width="16.779" height="27.901" viewBox="0 0 16.779 27.901">
                                <g id="Grupo_118" data-name="Grupo 118" transform="translate(13.95 2.828) rotate(90)">
                                    <line id="Línea_4" data-name="Línea 4" x2="11.122" y2="11.122" transform="translate(0 0)" fill="none" stroke="#434343" strokeLinecap="round" strokeWidth="4"/>
                                    <line id="Línea_5" data-name="Línea 5" x1="11.122" y2="11.122" transform="translate(11.122 0)" fill="none" stroke="#434343" strokeLinecap="round" strokeWidth="4"/>
                                </g>
                                </svg>

                            <ul className={(this.state.menuActived)? "active" : ""}>
                                <li className="lng">

                                <span className={ (currentLng==='es' ? 'active' : '') } onClick={()=>this.onClick('es')}>{this.props.t('language.long.spanish')}</span>
                                /
                                <span className={ (currentLng==='en' ? 'active' : '') } onClick={()=>this.onClick('en')}>{this.props.t('language.long.english')}</span>
                                </li>
                                <li className="contact" onClick={this.myaccount}>{this.props.t('header.myaccount')}</li>
                                <li className="contact" onClick={this.contact}>{this.props.t('footer.contactus')}</li>
                                <li className="contact" onClick={this.requestdemo}>{this.props.t('footer.requestdemo')}</li>
                                <li className="favorites" onClick={this.favorites}>{this.props.t('header.favorites')}</li>
                                <li className="logout" onClick={this.logout}>{this.props.t('general.header.logout')}</li>
                            </ul>
                            
                        </div>
            );
    }
}
//export default withTranslation()(UserComponent);
export default connect(({ avatarReducer }) => { return { avatarReducer } }, {})(withTranslation()(UserComponent));