const initialState = { avatar: "" };

// action es el valor devuelto por el action
//action.payload será el valor que quiero añadir, borrar, etc
export default (state = initialState, action) => {
    if (action.type === 'UPDATE_AVATAR') {
        return {
            ...state, //Lo que devuelve un reducer es lo que se quedará en el state, por tanto, debe devolver todo lo que había antes (además de la información que cambia)
            avatar: action.playload
        }
    }

    return state;
};

export const avatarReducer = state => state.avatarReducer.avatar;