import React from 'react';
import Header from '../common/header/header';
import Footer from '../common/footer/footer';
import Spinner from '../common/spinner/spinner';
import Services from '../../service/service';
//import {Link} from 'react-router-dom';
//import { Translation } from 'react-i18next';
import parse from 'html-react-parser';
import './style.scss';

export default class Static extends React.Component {


    constructor(props){
        super(props);
        this.props = props;
        this.services = Services;
        this.state={
            spinner: false,
            title:null,
            text: null, 
            className: null,
            currentLng: localStorage.getItem('i18nextLng')
        }
    }
    spinner = (value) => {
        this.setState({ spinner: value });
    }
    onhChange = async (value) => {
        if(this.mounted) {this.getContent(this.props);}  
    }

    componentWillUnmount(){
        this.mounted = false;
    }


    async getContent(props){
        this.spinner(true);
        this.mounted = true;
        let page = await this.services.post("videos/getPage", {"url": props.url});
        page = (page.status)? page.data["_source"] : null;

        if(page){
            let titleSource, descriptionSource;
            if(localStorage.getItem('i18nextLng')==='es'){
                titleSource = "titesp";
                descriptionSource = "descesp";
            }else{
                titleSource = "titeng";
                descriptionSource = "desceng";
            }

            if(this.mounted) {
                this.setState({ title: page[titleSource], text: page[descriptionSource], className: page["url"]});
                window.scrollTo(0, 0);
            }
        }
        this.spinner(false);
    }

    async UNSAFE_componentWillReceiveProps(next_props){
        if(this.state.url !== next_props.url){
            await this.getContent(next_props);
        }
    }

    async componentDidMount(){
        await this.getContent(this.props);
    }

    render(){

        const {title, text, className} = this.state;

        return (
            <div className="fullcontainer" id="StaticPage">
                <Header  onhChange={this.onhChange} sticky={true} {...this.props} />
                {title &&
                <div className="container-flex">
                    <div className="row">
                        <div className="col-12">               
                            <h2 className={className}>{title}</h2>
                        </div>
                    </div>
                </div>
                }
                {text &&
                <div className="container-flex text">
                    {parse(text)}
                </div>
                }
                <Footer />
                <Spinner show={this.state.spinner}/>
            </div>
        );
    }
}