
const Auth = {
    isAuth(getUrl = false){
        const isAuth =  (sessionStorage.getItem('token'))? true : false;   
        if(getUrl && isAuth===false){
            sessionStorage.setItem('url', window.location.href);
        }

        return isAuth;
    },
    login(arr){
      
        sessionStorage.setItem('token', arr.token);
        sessionStorage.setItem('email', arr.email);
        sessionStorage.setItem('avatar', arr.avatar);
    },
    logout(){
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('email');
        sessionStorage.removeItem('avatar');
        sessionStorage.removeItem('url');
        
    },
    getToken(){
        return sessionStorage.getItem('token');
    },
    getEmail(){
        return sessionStorage.getItem('email');
    },
    getImage(){
        //debugger;
        let avatar =  sessionStorage.getItem('avatar');
        const defaultImage = "/user.svg";

        let image = defaultImage;
        if(avatar && avatar!=='undefined'){
            image = (avatar.indexOf("blob:")>-1)? avatar : "/avatars/" + avatar;
        }

        return image;
    },
    setImage(avatar){
        sessionStorage.setItem('avatar', avatar);
    }
  }
export default (Auth);

