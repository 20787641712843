import React from 'react';
//import { Translation } from 'react-i18next';
//import {Link} from 'react-router-dom';
import Video from '../../common/video/video';
import Services from '../../../service/service';
import './style.scss';
export default class Outstanding extends React.Component {

    constructor(props){
        super(props);
        this.props = props;
        this.services = Services;
        this.state={
            videos:[]
        }
    }

    getVideos = async filter =>{
        let videos = await this.services.post("videos/getVideos", {limit:5, filter: filter});
        videos =  (videos.status)? videos.data : [];
        if(this.mounted) {this.setState({ videos });}   
    }
    
    componentWillUnmount(){
        this.mounted = false;
    }

    async componentDidMount(){
        this.mounted = true;
        await this.getVideos({});   
    }

    async UNSAFE_componentWillReceiveProps(next_props){
        await this.getVideos(next_props.filter);
    }

    shouldComponentUpdate(next_props, next_state) {
        return (next_props !== next_state);
    }

    render(){
        return (
            <div className="outstanding container-fluid">
                {this.state.videos[0] &&
                <div id="galeria">
                    <div className="col1">
                        <Video textposition="insite" video={this.state.videos[0] || false} />
                    </div>
                    <div className="col2">
                        <div id="galeria2">
                            <div className="col1">
                                <Video textposition="insite" video={this.state.videos[1] || false} />
                                <Video textposition="insite" video={this.state.videos[2] || false} />
                            </div>
                            <div className="col2">
                                <Video textposition="insite" video={this.state.videos[3] || false} />
                                <Video textposition="insite" video={this.state.videos[4] || false} />
                            </div>
                        </div>
                    </div>       
                </div>
            }
            </div>
            );
    }
}