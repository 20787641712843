import React from 'react';
import './style.scss';

export default class Spinner extends React.Component {
    constructor(props){
		  super(props);
          this.props = props;
          this.state={
              show: this.props.show
          }
    }

    async UNSAFE_componentWillReceiveProps(next_props){
        if(this.props.show !== next_props.show){
            this.setState({show: next_props.show});
        }
    }

    render(){
        if(this.state.show){
        return (
          <div id="spinner" >
          <div className="cssload-thecube">
              <div className="cssload-cube cssload-c1"></div>
              <div className="cssload-cube cssload-c2"></div>
              <div className="cssload-cube cssload-c4"></div>
              <div className="cssload-cube cssload-c3"></div>
          </div>
          </div>
        );
        }else{
            return(<div></div>);
        }
    }
}