import React from 'react';
import Header from '../common/header/header';
import Footer from '../common/footer/footer';
import Spinner from '../common/spinner/spinner';
import Services from '../../service/service';
import parse from 'html-react-parser';
import { Translation } from 'react-i18next';

import countries from '../home/register/countries.json';
import typeCompany from '../home/register/typeCompany.json';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Auth from '../../Auth/auth';
import './style.scss';

import {connect} from 'react-redux';
import updateAvatar from '../../store/avatar/action';


class MyAccount extends React.Component {


    state = {
        value: '',
        suggestions: [],
        response: {
            status:"",
            message:""
        },
        submit: false,

    }


    constructor(props){
        super(props);
        this.props = props;
        

        this.countries = countries;
        this.typeCompany = typeCompany;
        this.services = Services;


        this.state={
            value: '',
            suggestions: [],
            response: {
                status:"",
                message:""
            },
            showDelete: false,
            deletedMessage: false,
            message: {status: false, text:null, type:null},
            form:{
                firstName:{value:"", error:"",isValid:false},
                lastName:{value:"", error:"",isValid:false},
                email:{value:"", error:"",isValid:false},
                phone:{value:"", error:"",isValid:false},
                country:{value:"", error:"",isValid:false},
                password:{value:"", error:"",isValid:false},
                repeatPassword:{value:"", error:"",isValid:false},
                company:{value:"", error:"",isValid:false},
                typeCompany:{value:"", error:"",isValid:false},
                positionCompany:{value:"", error:"",isValid:false}

            },
            submit: false,
            spinner: false,
            myAccount:null,
            category: null,
            currentLng: localStorage.getItem('i18nextLng')
        }
        
        this.ref = React.createRef();   
    }


    logout = () =>{
        Auth.logout();
        window.location ='/login';
    }

    getUser = async ()=>{
        this.spinner(true);

        let form = {
            firstName:{value:"", error:"",isValid:false},
            lastName:{value:"", error:"",isValid:false},
            email:{value:"", error:"",isValid:false},
            phone:{value:"", error:"",isValid:false},
            country:{value:"", error:"",isValid:false},
            password:{value:"", error:"",isValid:false},
            repeatPassword:{value:"", error:"",isValid:false},
            company:{value:"", error:"",isValid:false},
            typeCompany:{value:"", error:"",isValid:false},
            positionCompany:{value:"", error:"",isValid:false}
        };

        let myAccount = await this.services.post("videos/getMyAccount");
        if (myAccount.status){
            myAccount = (myAccount.data || {})._source;

            form.firstName.value = myAccount.firstName;
            form.firstName.isValid = true;
            form.lastName.value = myAccount.lastName;
            form.lastName.isValid = true;
            form.email.value = myAccount.email;
            form.email.isValid = true;
            form.phone.value = myAccount.phone;
            form.phone.isValid = true;
            form.country.value = myAccount.country;
            form.country.isValid = true;
            form.company.value = myAccount.company;
            form.company.isValid = true;
            form.typeCompany.value = myAccount.typeCompany;
            form.typeCompany.isValid = true;
            form.positionCompany.value = myAccount.positionCompany;
            form.positionCompany.isValid = true;
            form.password.value = "change.Password";
            form.password.isValid = true;
            form.repeatPassword.value = "change.Password";
            form.repeatPassword.isValid = true;


        }
        this.spinner(false);
        if(this.mounted) {this.setState({ form: form });}   
    }
    
    componentWillUnmount(){
        this.mounted = false;
    }

    async componentDidMount(){
        this.mounted = true;
        await this.getUser();   
    }

    spinner = (value) => {
        this.setState({ spinner: value });
    }

    onSubmit = async event => {
        event.preventDefault();
        this.spinner(true);
        const isSubmited = this.validateSubmit();

        if(isSubmited){
            let response = {};
            let f = Object.assign({}, this.state.form); 
            let res = await this.services.post('videos/setMyAccount', f);
            response = {status:((res.status && res.data)? "ok": "error"), message: res.data}; 
            this.setState({message: {status: true, text: "myaccount.userUpdated." + response.status, type: response.status}});
        }
        this.spinner(false);
    }
    
    onChangeCountry = (event, newValue, method)=>{
        
        let new_state = Object.assign({}, this.state); 
        let f = new_state.form;
        f["country"] = {value:((newValue || {}).name || "") , error:"", isValid:false};
        this.setState({form: f});
        this.validate("country", false);
    }
    onChangeTypeCompany = (event, newValue, method)=>{
        
        let new_state = Object.assign({}, this.state); 
        let f = new_state.form;
        f["typeCompany"] = {value: ((newValue || {}).name || "")  , error:"", isValid:false};
        this.setState({form: f});
        this.validate("typeCompany", false);
    }
    

    onChange = event => {
        
        let new_state = Object.assign({}, this.state); 
        let f = new_state.form;
        f[event.target.name] = {value:event.target.value, error:"", isValid:false};
        this.setState({form: f, submit: false});
        this.validate(event.target.name, false);
    };

    onBlur = event => {
        this.validate(event.target.name, true);     
    }
    onBlurCountry = event => {
        this.validate("country", true);     
    }
    onBlurTypeCompany = event => {
        this.validate("typeCompany", true);     
    }
    

    validateSubmit(){
        return (Object.values(this.state.form).filter(obj=>(!(obj.isValid))).length === 0);    
    }

    validate = (field,refresh, sub=false) => {
        const value = this.state.form[field].value;
        let error = "";
        switch(field){
            case "email":
                const email = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/;
                error = (!email.test(value))? "register.error.email" : "";
                break;
            case "phone":
                //const phoneno = /^\+?([0-9]{2})?\)?[-. ]?([0-9]{3})[-. ]?([0-9]{3})[-. ]?([0-9]{3})$/;
                //error = (!phoneno.test(value))? "register.error.phone" : "";
                error = (value.length < 6)? "register.error.phone" : "";
                break;
            case "password":
                error = (value.length < 6)? "register.error.password" : "";
                if(!sub) this.validate('repeatPassword', refresh, true);
                break;
            case "repeatPassword":
                error = (value !== this.state.form.password.value)? "register.error.repeatPassword" : "";
                if(!sub) this.validate('password', refresh, true);
                break;
            
            case "comment":
               error = ""; //(value.length < 10)? "register.error.textarea" : "";
                break;
            case "country":
                error = (value.length < 2)? "register.error.country" : "";
                break;
            case "typeCompany":
                error = (value.length < 2)? "register.error.select" : "";
                break;
            default:
                error = (value.length < 2)? "register.error.text" : "";
        }

        let new_state = Object.assign({}, this.state); 
        let form = new_state.form;

        form[field]["error"] = (error && refresh)? error : "";
        form[field]["isValid"] = !(error);
        
        this.setState({form: form});
        return (error === "");
    }


    handleClick = event => {
        this.inputElement.click();
    }
    
    loadImage = async (event) => {
        this.spinner(true);

        if(event.currentTarget.files && event.currentTarget.files[0]){
            const file = event.currentTarget.files[0];
            const url = URL.createObjectURL(file);
            if(["image/png", "image/x-png","image/gif","image/jpeg","image/jpg"].includes(file.type)){

                let image = await this.services.uploadImage(file);
                //debugger;
                if (image.status && image.data !== false){
                    Auth.setImage(image.data);
                    this.props.updateAvatar(url);
                }
            }
        }
        this.spinner(false);
    }

    deleteCancel = (e) =>{
        this.setState({showDelete: false});
    }
    showDelete = (e) =>{
        this.setState({showDelete: true});
    }
    deleteConfirm = async (e) =>{
        this.spinner(true);

        let deletedMessage = false;
        let message = {status: false, text:"myaccount.deletedMessage.error", type:"deleteConfirm"};
        let res = await this.services.post('videos/deleteMyAccount');
        if (res.status && res.data === true){
            deletedMessage = true;
        }else{
            message.status = true;
        }
        this.spinner(false);
        this.setState({showDelete: false, message: message, deletedMessage: deletedMessage});
    }
    
    closeMessage = (e) => {
        this.setState({message: {status: false, text:null, type:null}});
    }

    getHomePage = () => {
        window.location = "/vod/";
    }

    render(){
        const currentLng = localStorage.getItem('i18nextLng') || 'en';
        const _countries = this.countries[currentLng];
        const _typeCompany = this.typeCompany[currentLng];
        const {form,  response, showDelete, deletedMessage, message } = this.state;
        let avatarStyle = {"backgroundImage": "url(" + (this.props.avatarReducer.avatar || Auth.getImage()) + ")"};
        return (

            <div className="fullcontainer" id="myAccount">
                    <Header reload="true" sticky={true} {...this.props} />
                    <div className="container vodContent">
                        <Translation>{t =>
                                    <div className="back" onClick={this.getHomePage}>
                                        <i className="icon"></i> {t("general.link.backtovideos")}
                                    </div>
                        }</Translation>
                    </div>

                    {showDelete &&
                        <Translation>{t =>
                                <div id="deleteConfirm">
                                    <h3>{t("myaccount.delete.text")}</h3>
                                    <button type="button" className="cancel" onClick={this.deleteCancel} >{t("myaccount.delete.cancel")}</button>
                                    <button type="button" className="confirm" onClick={this.deleteConfirm} >{t("myaccount.delete.confirm")}</button>
                                </div>
                        }</Translation>
                    }

                    {message.status &&
                        <Translation>{t =>
                            <div id="message">
                                <h3>{t(message.text)}</h3>
                                <button type="button" className="confirm" onClick={this.closeMessage} >{t("myaccount.deletedMessage.closed")}</button>
                            </div>
                        }</Translation>
                    }

                    {deletedMessage &&
                        <Translation>{t =>
                                <div id="deletedMessage">
                                    <h3>{t("myaccount.deletedMessage.text")}</h3>
                                    <button type="button" className="confirm" onClick={this.logout} >{t("myaccount.deletedMessage.closed")}</button>
                                </div>
                        }</Translation>
                    }

                    {(showDelete || deletedMessage || message.status) &&
                        <div className="fog"></div>
                    }
                <Translation>{t =>

                <div className="myAccount-form container-flex">
                    <div className="container vodContent">
                
                        <div className=" form-group row">
                            <div className="col-md-4">
                                <h2>{t("myaccount.title")}</h2>
                                <div className="avatarField" onClick={this.handleClick} 
                                style={avatarStyle}
                                />
                                <input 
                                    type="file" 
                                    onChange={this.loadImage}
                                    accept="image/x-png,image/gif,image/jpeg" 
                                    ref={input => this.inputElement = input} 
                                    style={{display: "none"}}
                                />
                            </div>
                            <div className="col-md-8">
                                <div className="">

                                    {response.status==="ok" &&
                                    <div className="card-body card-body-little">
                                        <div className="resultBox resultBox_ok">
                                            {parse(t(response.message))}
                                        </div>
                                        <p className="text">{parse(t("register.textok"))}</p>
                                    </div>
                                    }

                                    {(response.status==="error" || response.status==="") && 
                                    <div className="card-body">
                                        {response.status==="error" &&
                                        <div className="resultBox resultBox_error">
                                            {parse(t(response.message))}
                                        </div>
                                        }
                                        
                                        <form autoComplete="off" onSubmit={this.onSubmit}>                            
                                            <div className="form-group row">
                                                <div className="col-md-6">
                                                    <TextField  
                                                        name="firstName"
                                                        label={t('myaccount.name')}
                                                        onChange={this.onChange}
                                                        onBlur={this.onBlur}
                                                        value={form.firstName.value}
                                                        error={(form.firstName.error!=='')}
                                                        helperText={(form.firstName.error!=='')? t(form.firstName.error) : false}
                                                        required
                                                        fullWidth
                                                        margin="dense"
                                                        className={"MuiFormControl-root MuiTextField-root MuiFormControl-marginDense MuiFormControl-fullWidth " + ((form.firstName.isValid)? "isvalid" : "")}
                                                    />
                                                </div>    
                                                <div className="col-md-6">
                                                    <TextField  
                                                        name="lastName"
                                                        label={t('myaccount.lastname')}
                                                        value={form.lastName.value}
                                                        onChange={this.onChange}
                                                        onBlur={this.onBlur}
                                                        error={(form.lastName.error!=='')}
                                                        helperText={(form.lastName.error!=='')? t(form.lastName.error) : false}
                                                        required
                                                        fullWidth
                                                        margin="dense"
                                                        className={"MuiFormControl-root MuiTextField-root MuiFormControl-marginDense MuiFormControl-fullWidth " + ((form.lastName.isValid)? "isvalid" : "")}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <div className="col-md-6">
                                                    <TextField  
                                                        name="phone"
                                                        label={t('myaccount.phone')}
                                                        onChange={this.onChange}
                                                        onBlur={this.onBlur}
                                                        value={form.phone.value}
                                                        error={(form.phone.error!=='')}
                                                        helperText={(form.phone.error!=='')? t(form.phone.error) : false}
                                                        required
                                                        fullWidth
                                                        margin="dense"
                                                        className={"MuiFormControl-root MuiTextField-root MuiFormControl-marginDense MuiFormControl-fullWidth " + ((form.phone.isValid)? "isvalid" : "")}
                                                    />
                                                </div>

                                                <div className="col-md-6">
                                                    <Autocomplete
                                                        name="country"
                                                        onChange={this.onChangeCountry}
                                                        onBlur={this.onBlurCountry}
                                                        options={_countries}
                                                        value={{name:form.country.value}}
                                                        inputValue={form.country.value}
                                                        autoHighlight
                                                        autoSelect
                                                        autoComplete
                                                        getOptionLabel={(option) => option.name}
                                                        renderOption={(option) => (
                                                            <React.Fragment>{option.name}</React.Fragment>
                                                        )}
                                                        renderInput={(params) => (
                                                            <TextField
                                                            {...params}
                                                            label={t('myaccount.country')}
                                                            
                                                            inputProps={{
                                                                ...params.inputProps,
                                                                autoComplete: 'new-password', // disable autocomplete and autofill
                                                            }}
                                                    
                                                            margin="dense"
                                                            required
                                                            fullWidth
                                                
                                                            error={(form.country.error!=='')}
                                                            helperText={(form.country.error!=='')? t(form.country.error) : false}
                                                            className={"MuiFormControl-root MuiTextField-root MuiFormControl-marginDense MuiFormControl-fullWidth " + ((form.country.isValid)? "isvalid" : "")}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <div className="col-md-6">           
                                                    <TextField  
                                                        name="email"
                                                        label={t('myaccount.email')}
                                                        onChange={this.onChange}
                                                        onBlur={this.onBlur}
                                                        error={(form.email.error!=='')}
                                                        value={form.email.value}
                                                        helperText={(form.email.error!=='')? t(form.email.error) : false}
                                                        required
                                                        disabled
                                                        fullWidth
                                                        margin="dense"
                                                        className={"MuiFormControl-root MuiTextField-root MuiFormControl-marginDense MuiFormControl-fullWidth " + ((form.email.isValid)? "isvalid" : "")}
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <Autocomplete    
                                                        name="typeCompany"
                                                        options={_typeCompany}
                                                        onChange={this.onChangeTypeCompany}
                                                        onBlur={this.onBlurTypeCompany}
                                                        value={{name:form.typeCompany.value}}
                                                        inputValue={form.typeCompany.value}
                                                        autoHighlight
                                                        autoSelect
                                                        autoComplete
                                                        getOptionLabel={(option) => option.name}
                                                        renderOption={(option) => (
                                                            <React.Fragment>{option.name}</React.Fragment>
                                                        )}
                                                        renderInput={(params) => (
                                                            <TextField
                                                            {...params}
                                                            label={t('myaccount.typecompany')}
                                                            
                                                            inputProps={{
                                                                ...params.inputProps,
                                                                autoComplete: 'new-password', // disable autocomplete and autofill
                                                            }} 
                                                            margin="dense"
                                                            required
                                                            fullWidth
                                                            error={(form.typeCompany.error!=='')}
                                                            helperText={(form.typeCompany.error!=='')? t(form.typeCompany.error) : false}
                                                            className={"MuiFormControl-root MuiTextField-root MuiFormControl-marginDense MuiFormControl-fullWidth " + ((form.typeCompany.isValid)? "isvalid" : "")}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <div className="col-md-6">
                                                    <TextField  
                                                        name="company"
                                                        label={t('myaccount.company')}
                                                        onChange={this.onChange}
                                                        onBlur={this.onBlur}
                                                        value={form.company.value}
                                                        error={(form.company.error!=='')}
                                                        helperText={(form.company.error!=='')? t(form.company.error) : false}
                                                        required
                                                        fullWidth
                                                        margin="dense"
                                                        className={"MuiFormControl-root MuiTextField-root MuiFormControl-marginDense MuiFormControl-fullWidth " + ((form.company.isValid)? "isvalid" : "")}
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <TextField  
                                                        name="positionCompany"
                                                        label={t('myaccount.position')}
                                                        onChange={this.onChange}
                                                        onBlur={this.onBlur}
                                                        value={form.positionCompany.value}
                                                        error={(form.positionCompany.error!=='')}
                                                        helperText={(form.positionCompany.error!=='')? t(form.positionCompany.error) : false}
                                                        required
                                                        fullWidth
                                                        margin="dense"
                                                        className={"MuiFormControl-root MuiTextField-root MuiFormControl-marginDense MuiFormControl-fullWidth " + ((form.positionCompany.isValid)? "isvalid" : "")}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <div className="col-md-6">
                                                    <TextField  
                                                        name="password"
                                                        type="password" 
                                                        label={t('myaccount.password')}
                                                        onChange={this.onChange}
                                                        onBlur={this.onBlur}
                                                        value={form.password.value}
                                                        error={(form.password.error!=='')}
                                                        helperText={(form.password.error!=='')? t(form.password.error) : false}
                                                        required
                                                        fullWidth
                                                        margin="dense"
                                                        className={"MuiFormControl-root MuiTextField-root MuiFormControl-marginDense MuiFormControl-fullWidth " + ((form.password.isValid)? "isvalid" : "")}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <div className="col-md-6">
                                                    <TextField  
                                                        name="repeatPassword"
                                                        type="password" 
                                                        label={t('myaccount.repeatpassword')}
                                                        onChange={this.onChange}
                                                        onBlur={this.onBlur}
                                                        value={form.repeatPassword.value}
                                                        error={(form.repeatPassword.error!=='')}
                                                        helperText={(form.repeatPassword.error!=='')? t(form.repeatPassword.error) : false}
                                                        required
                                                        fullWidth
                                                        margin="dense"
                                                        className={"MuiFormControl-root MuiTextField-root MuiFormControl-marginDense MuiFormControl-fullWidth " + ((form.repeatPassword.isValid)? "isvalid" : "")}
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <div className="col-6 col-md-4">
                                                    <input 
                                                        type="submit" 
                                                        value={t("myaccount.save")} 
                                                        className="btn save_btn" />
                                                </div>
                                                <div className="col-6 col-md-4">
                                                    <button type="button" onClick={this.showDelete} className="btn delete_btn">{t("myaccount.delete")} </button>
                                                </div>
                                            </div>
                                        </form>    
                                    </div>
                                    }
                                </div>
                            </div>
                        </div>
                        </div>
</div>
                        }</Translation>
                    
                
                <Footer />
                    <Spinner show={this.state.spinner}/>
                </div>
            );
    }

}
export default connect(({ avatarReducer }) => { return { avatarReducer } }, {updateAvatar})(MyAccount);

