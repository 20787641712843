import React from 'react';
import Services from '../../../service/service';
import {connect} from 'react-redux';
import {addStar, delStar} from '../../../store/star/action';

import './style.scss';
class Like extends React.Component {
   
    constructor(props){
        super(props);
        this.props = props;
        this.services = Services;
        this.setLike = this.setLike.bind(this);
    }

    componentWillUnmount(){
        this.mounted = false;
    }
    
    async componentDidMount(){
        await this.getLike(this.props);
    }
   
    async getLike(props){
        this.mounted = false;
        let like = await this.services.post("videos/getlike", {_id: this.props.id});
        like = (like.status)? ((like.data|| {}).containerId === this.props.id) : false;
        this.mounted = true;   
        if(like === false){
            //this.props.delStar(this.props.id);
        }else{
            this.props.addStar(this.props.id);
        }
     
    }
    
   
    async setLike(e) {
        e.preventDefault();
        let like = await this.services.post("videos/setlike", {_id: this.props.id});
        like = (like.status)? like.data : false;
        if(like === false){
            this.props.delStar(this.props.id);
        }else{
            this.props.addStar(this.props.id);
        }

       // if(this.mounted) {this.setState({ like });}
    }

    render(){
        const img = ((this.props.starReducer.stars || []).includes(this.props.id) )? "/favorite-on.svg" : "/favorite-off.svg";
        //const classVal= (this.mounted)? "favorite" : "favorite opacity";
        const classVal= "favorite";
        return (
            <img className={classVal} alt="favorite" src={img} onClick={this.setLike} />
        );
    }
}
export default connect(({ starReducer }) => { return { starReducer } }, {addStar, delStar})(Like);