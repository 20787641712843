import React from 'react';
import { Translation } from 'react-i18next';
//import {Link} from 'react-router-dom';
import Video from '../../common/video/video';
import Services from '../../../service/service';
import './style.scss';

export default class Favorites extends React.Component {

    constructor(props){
        super(props);
        this.props = props;
        this.services = Services;
        this.state={
            videos:[],
            message:""
        }
    }

    getVideos = async (filter, search)=>{
        let message ="";
        let videos = await this.services.post("videos/favorites", {limit:100, filter: filter, pager: true, text: search});
        if (videos.status){
            videos = videos.data;
            message= (videos.length)? "" : "No hay resultados";
        }else{
            videos=[];
            message="No hay resultados";
        }
        
        if(this.mounted) {this.setState({ videos, message });}   
    }
    
    componentWillUnmount(){
        this.mounted = false;
    }

    async componentDidMount(){
        this.mounted = true;
        await this.getVideos(this.props.filter, this.props.search);   
    }

    async UNSAFE_componentWillReceiveProps(next_props){
        await this.getVideos(next_props.filter, next_props.search);
    }

    shouldComponentUpdate(next_props, next_state) {
        return (next_props !== next_state);
    }

    render(){
        return (
      
     
            <div className="favorite search container">
                <Translation>{t =>
                <h2> {t("myfavorite.title")}</h2>
                }</Translation>


                 {this.state.message ? (
                     <div></div>
                 ):(<div className="row">
                    {this.state.videos.map((vid, i) => {                     
                        return (<div key={vid["_id"]} className="col-md-4"><Video textposition="outsite" video={vid || false} /></div>) ;
                    })}
                </div>)
                }
            </div>
            );
    }
}