import React from 'react';
import Carousel from 'react-multi-carousel';
import Video from '../../common/video/video';
import Services from '../../../service/service';
import 'react-multi-carousel/lib/styles.css';
import './styleCarrousel.scss';

export default class OutstandingCarrousel extends React.Component {

    constructor(props){
        super(props);
        this.props = props;
        this.services = Services;
        this.state={
            videos:[]
        }
    }

    getVideos = async filter =>{
        let videos = await this.services.post("videos/getVideos", {limit:5, filter: filter});
        videos =  (videos.status)? videos.data : [];
        if(this.mounted) {this.setState({ videos });}   
    }
    
    componentWillUnmount(){
        this.mounted = false;
    }

    async componentDidMount(){
        this.mounted = true;
        await this.getVideos({});   
    }

    async UNSAFE_componentWillReceiveProps(next_props){
        await this.getVideos(next_props.filter);
    }

    shouldComponentUpdate(next_props, next_state) {
        return (next_props !== next_state);
    }

    render(){


        const responsive = {
            desktop: {breakpoint: { max: 3000, min: 1024 },items: 3, slidesToSlide: 1 },
            tablet: { breakpoint: { max: 1024, min: 768 }, items: 2, slidesToSlide: 1 },
            mobile: { breakpoint: { max: 768, min: 0 },items: 1, slidesToSlide: 1 }
          };
          

        const ButtonGroup = ({ next, previous, ...rest }) => {
            const {
                carouselState: { currentSlide, totalItems, slidesToShow }
            } = rest;

            return (
                <div className="carousel-button-group">

                    <svg className={currentSlide === 0 ? "leftb disable" : "leftb"} onClick={() => previous()} xmlns="http://www.w3.org/2000/svg" width="16.779" height="27.901" viewBox="0 0 16.779 27.901">
                    <g id="Grupo_118" data-name="Grupo 118" transform="translate(13.95 2.828) rotate(90)">
                        <line id="Línea_4" data-name="Línea 4" x2="11.122" y2="11.122" transform="translate(0 0)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="4"/>
                        <line id="Línea_5" data-name="Línea 5" x1="11.122" y2="11.122" transform="translate(11.122 0)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="4"/>
                    </g>
                    </svg>


                    <svg className={currentSlide >= totalItems - slidesToShow ? "rightb disable" : "rightb"} onClick={() => next()} xmlns="http://www.w3.org/2000/svg" width="16.778" height="27.9" viewBox="0 0 16.778 27.9">
                    <g id="Grupo_117" data-name="Grupo 117" transform="translate(13.949 2.828) rotate(90)">
                        <line id="Línea_4" data-name="Línea 4" y1="11.121" x2="11.122" fill="none" stroke="#fff"  strokeLinecap="round" strokeWidth="4"/>
                        <line id="Línea_5" data-name="Línea 5" x1="11.122" y1="11.121" transform="translate(11.122)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="4"/>
                    </g>
                    </svg>

                </div>
            );
        };

        if(this.state.videos && this.state.videos.length>0){
        return (
          <div className={"outstandingcarrousel-item"}>
          <div className="container-flex">
            <div className="carrousel-videos row">
                <div className="col-md-12 carrousel-row">
                  <Carousel
                    key={"carro_"+ this.props.label}
                    additionalTransfrom={0}
                    autoPlaySpeed={3000}
                    centerMode={false}
                    className=""
                    containerClass="vsncarrousel"
                    arrows={false}
                    renderButtonGroupOutside={true}
                    customButtonGroup={<ButtonGroup
                                        next={this.props.next}
                                        previous={this.props.previous}
                                        rest={this.props.rest}
                                      />}
                    dotListClass=""
                    draggable
                    focusOnSelect={false}
                    infinite={true}
                    itemClass=""
                    keyBoardControl
                    minimumTouchDrag={80}
                    renderDotsOutside={false}
                    removeArrowOnDeviceType={["tablet", "mobile"]}
                    deviceType={this.props.deviceType}
                    responsive={responsive}
                    showDots={false}
                    sliderClass=""
                    slidesToSlide={1}
                    swipeable
                  >
                    {this.state.videos.map((vid, i) => <Video textposition="insite" video={vid} key={vid._id} />)}
                  </Carousel>
                </div>
          </div>
          </div>
          </div>
        );
        }else{
          return(<span className="none"></span>)
        }

    }
}