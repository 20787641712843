import Auth from '../Auth/auth';
import axios from 'axios';



const Service = {



    async uploadImage(image){

      try{
        const formData = new FormData();
        formData.append('file', image);
  
        let headers = (Auth.isAuth())? {'Authorization': 'Bearer ' + Auth.getToken()} : {};
        headers["lng"] = localStorage.getItem('i18nextLng');
        headers['Content-Type'] = 'multipart/form-data';
        

        let res = await axios({
          method: "post",
          url: process.env.REACT_APP_APIURI +"/videos/avatarUploadImage",
          data: formData,
          headers: headers
          });

        // Si la respuesta no tiene la estructura que esperamos algo no funciona
        if(!res || !res.data || !res.data.status || !res.data.status.code)
          throw new Error("remember.req.internalError");
        
        // Si el codigo de respuesta es de error
        if(res.data.status.code >= 400){
          if(res.data.status.code === 504){
            Auth.logout();
            window.location.href = "/";
            return false;
          }else{
            return {status: false, 
                    data: res.data.status.message, 
                    code: res.data.status.code, 
                    params:res.data.data 
                  };
          }
        }

        return {status: true, 
                data: res.data.data, 
                code: res.data.status.code
              };

      }catch(e){
        return {status: false, 
                data: e.message, 
                code: 500, 
                params:{}
              };
      }

    },

    async get(action, params={}){
      return await this.send('get', action, params);
    },

    async post(action, params={}){
      return await this.send('post', action, params);
    },

    async send(method, action, params={}){
      try{
        let headers = (Auth.isAuth())? {'Authorization': 'Bearer ' + Auth.getToken()} : {};
        headers["lng"] = localStorage.getItem('i18nextLng');
        
        let res =  await axios({
                        method: method,
                        url: process.env.REACT_APP_APIURI +"/"+ action,
                        data: params,
                        headers: headers
                        });

        // Si la respuesta no tiene la estructura que esperamos algo no funciona
        if(!res || !res.data || !res.data.status || !res.data.status.code)
          throw new Error("remember.req.internalError");
        
        // Si el codigo de respuesta es de error
        if(res.data.status.code >= 400){
          if(res.data.status.code === 504){
            Auth.logout();
            window.location.href = "/";
            return false;
          }else{
            return {status: false, 
                    data: res.data.status.message, 
                    code: res.data.status.code, 
                    params:res.data.data 
                  };
          }
        }

        return {status: true, 
                data: res.data.data, 
                code: res.data.status.code
              };

      }catch(e){
        return {status: false, 
                data: e.message, 
                code: 500, 
                params:{}
              };
      }
    }
  }

export default (Service);