import React from 'react';
import ReactDOM from 'react-dom';
import { withTranslation } from 'react-i18next';
import { MultiSelectOptionMarkup } from "./MultiSelectOptionMarkup";
import { CaretIcon } from "./CaretIcon";

import './style.scss';



class Filter extends React.Component {

    constructor(props){
        super(props);
        this.props = props;

        this.state = {
            visible: false
        };
    }

 
    pushData = (obj, result) => {
      let titleSource;
      if(localStorage.getItem('i18nextLng')==='es'){
        titleSource = "textesp";
      }else{
        titleSource = "texteng";
      }
      obj = (obj.status)? obj.data : [];
      for(let i=0, len=obj.length; i<len; i++){
        result.push({value: obj[i]["_source"]["name"],text: obj[i]["_source"][titleSource], 
          markup: <MultiSelectOptionMarkup>{obj[i]["_source"][titleSource]}</MultiSelectOptionMarkup>});
      }
      return result;
    }


    componentWillUnmount(){
        this.mounted = false;
        document.removeEventListener('click', this.handleClickOutside, true);
    }

   componentDidMount(){
      this.mounted = true;
      document.addEventListener('click', this.handleClickOutside, true);
    }


    handleClickOutside = event => {
      const domNode = ReactDOM.findDOMNode(this);
      if (!domNode || !domNode.contains(event.target)) {
          this.setState({visible: false});
      }
  }

    OnSelectItem = event => {
      event.preventDefault();
      let new_state = Object.assign({}, this.props); 
      let filter = new_state.filter;
      const data = event.target.attributes.data.value;
    

      const index = filter.indexOf(data);
      if(index > -1){
        filter.splice(index, 1);
      }else{
        filter.push(data);
      }

      this.props.onfChange(filter, this.props.name);
    }

    

    
    toVisible = (event) =>{
      if((event.target.attributes.class.value) === "select"){
        this.setState({ visible: !this.state.visible });
      }
    }

    render(){
        const {visible} = this.state;
        const filter = this.props.filter || [];
        const itemsList = this.props.itemsList || [];
        let items = [];
        items = this.pushData( itemsList, items);
        return (
                  <div className={"col-md " + this.props.colclass}>

                      <div className="multiselect-filter">
                          <div className="select" onClick={this.toVisible}>
                            <div className="label">{this.props.t(this.props.label)} <CaretIcon className="caret-icon" /></div>
                            <div className={"options " + ((visible)? 'active': '')}>
                              <ul>
                                {items.map((item, i) => {                     
                                  return (<li key={item["value"]} className={(filter.includes(item["value"]))? "selected":""} onClick={this.OnSelectItem} data={item["value"]}>{item["markup"]}</li>) ;
                                })}
                                
                              </ul>
                          </div>
                          </div>
                          
                      </div>
                  </div>
        );
    }
}

export default withTranslation()(Filter);