const initialState = { stars: [] };

// action es el valor devuelto por el action
//action.payload será el valor que quiero añadir, borrar, etc
export default (state = initialState, action) => {
    if (action.type === 'ADD_STAR') {
        if(!state.stars.includes(action.playload)){
            state.stars.push(action.playload);
        }

        return {
            ...state, //Lo que devuelve un reducer es lo que se quedará en el state, por tanto, debe devolver todo lo que había antes (además de la información que cambia)
            stars: state.stars
        }
    }

    if (action.type === 'DEL_STAR') {

        const index = state.stars.indexOf(action.playload);
        if (index > -1) {
            state.stars.splice(index, 1);
        }

        return {
            ...state, //Lo que devuelve un reducer es lo que se quedará en el state, por tanto, debe devolver todo lo que había antes (además de la información que cambia)
            stars: state.stars
        }
    }
    return state;
};

export const starReducer = state => state.starReducer.stars;