import React from 'react';
import Header from '../common/header/header';
import Login from './login/login';
import Register from './register/register';
import Remember from './remember/remember';
import Footer from '../common/footer/footer';
import Spinner from '../common/spinner/spinner';
import Restore from './restorepass/restorepass';
import Invitation from './invitation/invitation';
import ActionServer from './actionserver/actionserver';


import './style.scss';

export default class Home extends React.Component {

    constructor(props){
        super(props);
        this.props = props;

        this.state = {
            spinner: false,
            stickyHeader: false
        }
  
    }
    spinner = (value) => {
        this.setState({ spinner: value });
    }
    componentDidMount= () => {
        window.addEventListener('scroll', this.handleScroll);
    }
    
    componentWillUnmount = () => {
        window.removeEventListener('scroll', this.handleScroll);
    }
    
    handleScroll = () => {
        let newState = this.state.stickyHeader;
        if(this.state.stickyHeader){
            newState = (window.pageYOffset === 0)? false : true;
        }else{
            newState = (window.pageYOffset >60);
        }
  
        if(this.state.stickyHeader !== newState){
            this.setState({stickyHeader: newState});
        }
    }


    render(){
       
        const handle = (this.props.match || []).params || [];
        return (
            <div className="fullcontainer" id="HomeContainer">
                <Header sticky={this.state.stickyHeader}/>
                <header id="header">
                    <div className="overlay"></div>
                    <div className="back"></div>
                    <video playsInline="playsinline" autoPlay="autoplay" muted="muted" loop="loop">
                        <source src="/video.mp4" type="video/mp4" />
                    </video>
                    
                    <div className="container card-container">
                        <div className="d-flex justify-content-center h-100">

                            {(function() {
                                switch (this.props.defaultView) {
                                case 'login':
                                    return <Login spinner={this.spinner} {...this.props} />;
                                case 'register':
                                    return <Register spinner={this.spinner} {...this.props} />;
                                case 'remember':
                                    return <Remember spinner={this.spinner} {...this.props} />;
                                case 'restorepass':
                                    return <Restore  spinner={this.spinner}  handle={handle} />;
                                case 'invitation':
                                    return <Invitation  spinner={this.spinner}  handle={handle} />;
                                case 'validateemail':
                                case 'resentvalidationemail':
                                case 'reactivate':
                                    return <ActionServer spinner={this.spinner} action={this.props.defaultView} handle={handle} />; 
                                default:
                                }
                            }.bind(this))()}

                        </div>
                    </div>
                </header>
                <Footer />
                <Spinner show={this.state.spinner} />
            </div>
        );
    }
}
