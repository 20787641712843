import React from 'react';
import { withTranslation } from 'react-i18next';
import {Link} from 'react-router-dom';
import UserComponent from '../user/user';
import Auth from '../../../Auth/auth';
import './style.scss';


class Header extends React.Component {

    constructor(props){
        super(props);
        this.props = props;
		this.state = {
            currentLng: localStorage.getItem('i18nextLng'),
            search: "",
            searcherActive: false
        }
    }



    onhChange = (value) =>{
        if(this.props.onhChange){
            this.props.onhChange(value);
        }
    }

    onClick = (lng) => {
       this.props.i18n.changeLanguage(lng);
       this.setState({currentLng: localStorage.getItem('i18nextLng')});
       if(this.props.reload){
        window.location = "/vod";
       }else{
        this.onhChange(lng);
       }
    }
    
    onChange = event => {
		this.setState({search: event.target.value});         
    }

    onSubmit = event => {
        event.preventDefault();
        this.props.history.push('/vod/search/'+encodeURIComponent(this.state.search || "*"));
    }


    searcherActiveChange = () => {
        this.setState({searcherActive: !(this.state.searcherActive)});
    }
  
    render(){
    
		const { currentLng, searcherActive } = this.state; 
        let classNametoNav = (this.props.sticky)? "sticky" : "";
        classNametoNav += (searcherActive)? " searcher": "";

        
		return (
                <nav id="headernav" className={classNametoNav}>
                    <div className="container">
                     
                        <Link className="logo" to="/"></Link>
                     
                        {Auth.isAuth() &&
                        <div className={"search"}>
                            <form onSubmit={this.onSubmit}>
                                <input 
                                    type="text" 
                                    aria-label="Search" 
                                    onChange={this.onChange}
                                    value={this.state.search}
                                />
                                <span onClick={this.onSubmit}><i className="fas fa-search text-grey" aria-hidden="true"></i></span>
                            </form>
                        </div>
                        }
                        {Auth.isAuth() &&
                        <svg className="lupa-mobile-version" onClick={this.searcherActiveChange} xmlns="http://www.w3.org/2000/svg" width="12.9" height="14.9" viewBox="0 0 12.9 14.9">
                        <g id="lupa" transform="translate(-1647.476 -58)">
                            <g id="Elipse_1" data-name="Elipse 1" transform="translate(1647.476 58)" fill="none" stroke="#9a9a9a" strokeWidth="2">
                            <circle cx="5.5" cy="5.5" r="5.5" stroke="none"/>
                            <circle cx="5.5" cy="5.5" r="4.5" fill="none"/>
                            </g>
                            <line id="Línea_7" data-name="Línea 7" x2="3" y2="4" transform="translate(1655.976 67.5)" fill="none" stroke="#9a9a9a" strokeLinecap="round" strokeWidth="2"/>
                        </g>
                        </svg>
                        }


                        {Auth.isAuth() &&
                            <UserComponent />
                        }
                        {!Auth.isAuth() &&
                        <ul className="languages">
                            <li className={ (currentLng==='es' ? 'active' : '') }>
                                <button onClick={()=>this.onClick('es')}>{this.props.t('language.spanish')}</button>
                            </li>
                            <li className={ (currentLng==='en' ? 'active' : '') }>
                                <button onClick={()=>this.onClick('en')}>{this.props.t('language.english')}</button>
                            </li>
                        </ul>
                        }
                    </div>
                </nav>
            );
    }
}
export default withTranslation()(Header);