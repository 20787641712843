import React from 'react';
import Header from '../common/header/header';
import Footer from '../common/footer/footer';
import Error from '../error/error';
import Spinner from '../common/spinner/spinner';
import Services from '../../service/service';
import ReactPlayer from 'react-player'
import { withRouter } from 'react-router-dom';
import parse from 'html-react-parser';
import { Translation } from 'react-i18next';
import CarouselComponent from '../list/carousel/carousel';
import ListComponent from '../list/list/list';
import Like from '../common/like/like';

import './style.scss';



class Detail extends React.Component {

    constructor(props){
        super(props);
        this.props = props;
        this.services = Services;
        this.state={
            windowsType: this.windowsType(window.innerWidth),
            spinner: false,
            video:null,
            category: null,
            currentLng: localStorage.getItem('i18nextLng')
        }
        
        this.ref = React.createRef();   
    }

    spinner = (value) => {
        this.setState({ spinner: value });
    }

    componentWillUnmount(){
        this.mounted = false;
        window.removeEventListener('resize', this.updateDimensions);
    }
    
    async componentDidMount(){
        window.addEventListener('resize', this.updateDimensions);
        await this.getContent(this.props);
    }

    windowsType = width =>{
        return (width > 768)? 'desktop' : 'mobile';
    }
    
    updateDimensions = () => {
        const windowsType = this.windowsType(window.innerWidth);
  
        if(this.mounted && windowsType !== this.state.windowsType){
            this.mounted = false;
            this.setState({windowsType});
        }
    };


    onhChange = async (value) => {
        if(this.mounted) {this.setState({ currentLng: value });}  
    }
    async getContent(props){
        this.spinner(true);
        this.mounted = true;
        let video = await this.services.post("videos/getVideo", {_id: props.match.params.id});
        video = (video.status)? video.data : null;
        
        let _idCat = (video && video._source.solutions)? {"catSolutions":[video._source.solutions[0].name]} : [];
        let category = await this.services.post("videos/getsolutioncategory", {filter:_idCat});
        category = (category.status)? category.data : null;
        
        if(this.mounted) {this.setState({ video , category });}
        this.spinner(false);
    }

    async UNSAFE_componentWillReceiveProps(next_props){
        if(this.state.video._id !== next_props.match.params.id){
            await this.getContent(next_props);
        }
    }

    handleSeekUpdate(value) {
           this.ref.current.seekTo(parseFloat(value));
    }

    trasformSecondsToTime(d){
        d = d || 0;
        var sec_num = parseInt(d, 10);
        var hours   = Math.floor(sec_num / 3600);
        var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
        var seconds = sec_num - (hours * 3600) - (minutes * 60);
    
        if (hours   < 10) {hours   = "0"+hours;}
        hours  = (hours  !== "00")? hours +":" : "";
        if (minutes < 10) {minutes = "0"+minutes;}
        if (seconds < 10) {seconds = "0"+seconds;}
        return hours + minutes + ':' + seconds;
    }

    getHomePage(){
        //this.props.history.goBack
        window.location.href = '/vod';
    }

    render(){
      
        let titleSource, descriptionSource;
        if(localStorage.getItem('i18nextLng')==='es'){
            titleSource = "titesp";
            descriptionSource = "descesp";
        }else{
            titleSource = "titeng";
            descriptionSource = "desceng";
        }


        let {video, category, spinner, windowsType} = this.state;

        let img = "";
        if(video){
            if(video._source.imagePanelRadio === 'inlink'){
                img = (((video._source.image || [])[0] || {}).assetSrc) || false;
                img = (img)?  process.env.REACT_APP_APIURI + img : "";
            }else{
                img = ((video._source.externalImage || [])[0] || {}).assetSrc || "";
            }
        }


      if(spinner){
        return(<div className="fullcontainer" id="DetailContainer">
            <Spinner show={this.state.spinner}/>
        </div>);
      }else if(!video){
        return(<Error />);
      }else{
        this.mounted = true;
            return (
                <div className="fullcontainer" id="DetailContainer">
                    <Header reload="true" sticky={true} {...this.props} />
                    <div className="container vodContent">
                        <div className="row">
                            <div className="col-12">

                                <Translation>{t =>
                                <div className="back" onClick={this.getHomePage}>
                                    <i className="icon"></i> {t("general.link.backtovideos")}
                                </div>
                                }</Translation>

                                
                                {video && video._id &&  
                                <div className='player-wrapper'>
                                    {img &&
                                    <img alt={video["_source"][titleSource]} title={video["_source"][titleSource]} 
                                    src={img}  />
                                    }
                                    <ReactPlayer
                                    key={video._id}
                                    ref={this.ref}
                                    url={'https://vimeo.com/' + video["_source"]["idVimeo"]}
                                    className='react-player'
                                    playing
                                    controls
                                    width='100%'
                                    height='100%'
                                    />
                                </div>
                                }

                            </div>
                        </div>
                        {video && video._id &&  
                        <div className="row">
                            <div className={"col-md-"+((video._source.dataGrid.length > 1)? "8" : "12")}>
                                <h1>{video["_source"][titleSource]}<Like id={video["_id"]} /></h1>
                                <div className="description">{parse(video["_source"][descriptionSource])}</div>
                            </div>
                            {video._source.dataGrid.length > 1 &&
                            <div className="col-md-1 vertical-line"></div>
                            }
                            {video._source.dataGrid.length > 1 &&
                            <div className="col-md-3">
                                <Translation>{t =>
                                    <h2>{t("detailvideo.chapters")}</h2>
                                }</Translation>
                                <ul className="chapters">
                                    {video._source.dataGrid.map((value, index) => {
                                        return <li key={index} onClick={() => this.handleSeekUpdate(value.time)}><span>{value.title}</span><span className="time">{this.trasformSecondsToTime(value.time)}</span></li>
                                    })}
                                </ul>
                            </div>
                            }
                        </div>
                        }
                    </div>
                        {category &&
                        <div className="container-flex CarouselComponent" >
                            <div className="row">
                                <div className="col-sm-12">
                                {windowsType === "desktop" &&
                                    <Translation>{t =>                                
                                        <CarouselComponent 
                                            bg = {true}
                                            filter={[]} 
                                            type={category["_type"]}
                                            key={category["_source"]["name"]} 
                                            name={category["_source"]["name"]} 
                                            label={t("detail.othersVideos")}
                                        />
                                    }</Translation>
                                }
                                {windowsType === "mobile" &&
                                    <Translation>{t =>                                
                                        <ListComponent 
                                            bg = {true}
                                            filter={[]} 
                                            type={category["_type"]}
                                            key={category["_source"]["name"]} 
                                            name={category["_source"]["name"]} 
                                            label={t("detail.othersVideos")}
                                        />
                                    }</Translation>
                                }
                                </div>
                            </div>
                        </div>
                        }
                    <Footer />
                    <Spinner show={this.state.spinner}/>
                </div>
            );
        }
    }
}
export default withRouter(Detail) 