import React from 'react';
import { withTranslation } from 'react-i18next';
import './style.scss';



class ShowFilter extends React.Component {

    constructor(props){
        super(props);
        this.props = props; 
    }



    pushData = (obj, filters, itemsList, type) => {
      filters = filters[type] || [];
      itemsList = (itemsList[type] || {}).data || [];

      if(filters.length > 0 && itemsList.length > 0){
    
        let titleSource = (localStorage.getItem('i18nextLng')==='es')? "textesp" : "texteng";
      
        for(let i=0, len=filters.length; i<len; i++){
          let f = itemsList.filter(list => list._source.name === filters[i] );
          if(f && f[0] && f[0]["_source"]){
            obj.push({name: f[0]["_source"][titleSource], key:filters[i], type: type })
          }
        }
      }
      return obj;
    }


    delete = (key, type) => {

      let new_state = Object.assign({}, this.props); 
      let filter = new_state.filter;

      const index = filter[type].indexOf(key);
      if(index > -1){
        filter[type].splice(index, 1);
        this.props.onfChange(filter[type], type);
      }
    }

    
    deleteAll = () => {
      this.props.onfChange([], "ALL");
    }

    render(){

        const filters = this.props.filter || {};
        const itemsList = this.props.itemsList || {};
        let items = [];
        items = this.pushData(items, filters, itemsList, "catCategories");
        items = this.pushData(items, filters, itemsList, "catSolutions");
        items = this.pushData(items, filters, itemsList, "catProducts");
        return (
                  <div>
                    {items.length > 0 &&
                    <ul className="selectedFilters">
                      <li>{this.props.t("list.deleteFilter")} 
                        <svg onClick={this.deleteAll} xmlns="http://www.w3.org/2000/svg" width="14.477" height="14.477" viewBox="0 0 14.477 14.477">
                          <path id="cross" d="M293.369,290.484a1.024,1.024,0,0,0-1.024,1.024v3.072h-3.072a1.024,1.024,0,1,0,0,2.048h3.072V299.7a1.024,1.024,0,0,0,2.048,0v-3.072h3.072a1.024,1.024,0,0,0,0-2.048h-3.072v-3.072A1.024,1.024,0,0,0,293.369,290.484Z" transform="matrix(0.695, 0.719, -0.719, 0.695, 16.086, -409.137)" fill="white"/>
                        </svg>
                      </li>
                      
                      {items.map((item, i) => {                     
                          return (<li key={item["key"]}>
                                    {item["name"]}
                                    <svg onClick={()=>this.delete(item["key"], item["type"])} xmlns="http://www.w3.org/2000/svg" width="14.477" height="14.477" viewBox="0 0 14.477 14.477">
                                      <path id="cross" d="M293.369,290.484a1.024,1.024,0,0,0-1.024,1.024v3.072h-3.072a1.024,1.024,0,1,0,0,2.048h3.072V299.7a1.024,1.024,0,0,0,2.048,0v-3.072h3.072a1.024,1.024,0,0,0,0-2.048h-3.072v-3.072A1.024,1.024,0,0,0,293.369,290.484Z" transform="matrix(0.695, 0.719, -0.719, 0.695, 16.086, -409.137)" fill="white"/>
                                     </svg>
                                  </li>) ;
                      })}
                                

                    </ul>
                    }
                  </div>
        );
    }
}

export default withTranslation()(ShowFilter);