import React from 'react';
import Filter from '../filter/filter';
import ShowFilter from '../showFilter/showFilter';
import Services from '../../../service/service';
import './style.scss';



export default class Filters extends React.Component {

    constructor(props){
        super(props);
        this.props = props;
        this.services = Services;
        this.state = {
          itemsList: {}
      };
    }

    onfChange = (options, name) =>{
      this.props.onfChange(options,name);
    }



    async componentDidMount(){
      let itemsList = {};
      
      itemsList["catCategories"] = await this.services.post("videos/getcategories", {});
      itemsList["catSolutions"] = await this.services.post("videos/getsolutions", {});
      itemsList["catProducts"] = await this.services.post("videos/getproducts", {});

      this.mounted = true;
      if(this.mounted) {this.setState({ itemsList });}
    }

    

    render(){

        const {itemsList}  = this.state;
        return (
            <div className="vodfilter container-fluid">
              {this.mounted &&
              <div className="container">
                <div className="row">
                  <Filter 
                    onfChange={this.onfChange}
                    colclass="col-12"
                    currentLng={this.props.currentLng} 
                    api="getcategories" 
                    label="filter.label.categories" 
                    name="catCategories" 
                    itemsList={itemsList["catCategories"] || []} 
                    filter={((this.props.filter||{}).catCategories || [])} />

                  <Filter 
                    onfChange={this.onfChange} 
                    colclass="col-6"
                    currentLng={this.props.currentLng} 
                    api="getsolutions"  
                    label="filter.label.solutions" 
                    name="catSolutions"
                    itemsList={itemsList["catSolutions"] || []} 
                    filter={((this.props.filter||{}).catSolutions || [])}/>
                  
                  <Filter 
                    onfChange={this.onfChange} 
                    colclass="col-6"
                    currentLng={this.props.currentLng} 
                    api="getproducts"   
                    label="filter.label.products" 
                    name="catProducts"
                    itemsList={itemsList["catProducts"] || []} 
                    filter={((this.props.filter||{}).catProducts || [])}/>
                </div>
     
        
                <div className="row">
                  <ShowFilter 
                    onfChange={this.onfChange} 
                    currentLng={this.props.currentLng} 
                    itemsList={itemsList || {}} 
                    filter={this.props.filter} />
                </div>
              </div>
              }
            </div>
        );
    }
}