import React from 'react';
import {Link} from 'react-router-dom';
import Auth from '../../../Auth/auth';
import { Translation } from 'react-i18next';

import './style.scss';
export default class Footer extends React.Component {
    constructor(props){
		  super(props);
	  	this.props = props;
    }

    onClick = () =>{
      Auth.logout();

    }
    render(){
        return (
                <footer>
                  <div className="content">
                    <div className="info">
            
                      <Translation>{t => 
                      <div className="links">                    
                        <Link to="/legalnotice">{t("footer.legalnotice")}</Link>
                        <Link to="/cookiespolicy">{t("footer.cookiespolicy")}</Link>
                        <Link to="/privacypolicy">{t("footer.privacypolicy")}</Link>
                      </div>
                      }</Translation>
                      <Translation>{t => 
                      <div className="copyright">
                        <p>&copy; 2021 - {t("footer.rights")}</p>
                      </div>
                      }</Translation>
                    </div>
                  </div>
                </footer>
        );
    }
}