import React from 'react';
import CarouselComponent from '../carousel/carousel';
import Services from '../../../service/service';
//import { List, WindowScroller } from 'react-virtualized';
//import 'react-virtualized/styles.css'; // only needs to be imported once


import './style.scss';

export default class Carousels extends React.Component {

    constructor(props){
        super(props);
        this.props = props;
        this.services = Services;
        this.state={
            categories:[]
        }
        
    }

    getCategories = async filters =>{
        let categories = await this.services.post('videos/getallcategories', {filter: filters});
        categories = (categories.status)? categories.data : [];

        if(this.mounted) {this.setState({ categories });}
    }
  
    componentWillUnmount(){
        this.mounted = false;
    }

    async componentDidMount(){
        this.mounted = true;
        await this.getCategories({});   
    }

    async UNSAFE_componentWillReceiveProps(next_props){
        await this.getCategories(next_props.filter);
    }

    shouldComponentUpdate(next_props, next_state) {
        return (next_props !== next_state);
    }


render () {

    let titleSource;//, descriptionSource;
    if(localStorage.getItem('i18nextLng')==='es'){
        titleSource = "textesp";
    }else{
        titleSource = "texteng";
    }


    return (
        <div className="container-flex carrousels">
    {this.state.categories.map((cat, i) => {             

        return (<CarouselComponent 
              bg = {false}
              filter={this.props.filter} 
              type={cat["_type"]}
              key={cat["_source"]["name"]} 
              name={cat["_source"]["name"]} 
              label={cat["_source"][titleSource]}
          />) ;
      })}
      </div>
    );
}
    
}
