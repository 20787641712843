import React from 'react';
//import { Translation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import {Link} from 'react-router-dom';
import Auth from '../../../Auth/auth';
import Services from '../../../service/service';
//import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import parse from 'html-react-parser';


/*
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import FilledInput from '@material-ui/core/FilledInput';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
*/



import './style.scss';

class Login extends React.Component {

    state ={
        isValidForm: false,
        form:{
			email: {value:"", isValid:false, error:""},
			password: {value:"", isValid:false, error:""}
		},
		response: {status:"", message: "", params:{}}
    }
    constructor(props){
		super(props);
		this.props = props;
		this.services = Services;
		
    }


    onSubmit = async event => {
		this.props.spinner(true);
        event.preventDefault();
        let res = await this.services.post('_users/signin', {
															email: this.state.form.email.value, 
															password: this.state.form.password.value
														});
		if(res.status){
			Auth.login(res.data);
			window.location.href = ( sessionStorage.getItem('url') || '/vod');
        }else{
			let message = "";
			switch(res.code){
				case 401:
					// pendingvalidate
					message = "login.req.pendingvalidate";
					break;
				case 403:
					// User expired
					message = "login.req.expired";
					break;
				case 412:
					// pendingemailvalidate
					message = "login.req.pendingemailvalidate";
					break;
				case 423:
					// locked
					message = "login.req.locked";
					break;
				default:
					// code error 500
					message = res.data;
					break;
			}
			let response = {status: res.code || 500, 
							message: message, 
							params: res.params || {}
						};
		   	this.setState({response}); 
		}
		this.props.spinner(false);
    }



    onChange = event => {
		let new_state = Object.assign({}, this.state); 
        let f = new_state.form;

        f[event.target.name] = {value:event.target.value, error:"", isValid:false};
		this.setState({form: f, isValidForm: false}); 
    };


    validate = (field,refresh, sub=false) => {
        const value = this.state.form[field].value;
        let error = "";
        switch(field){
            case "email":
                const email = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/;
                error = (!email.test(value))? "register.error.email" : "";
                break;
            case "password":
                error = (value.length < 6)? "register.error.password" : "";
				break;
			default:

        }

        let new_state = Object.assign({}, this.state); 
        let form = new_state.form;

        if(error){
            form[field].error = (refresh)? error : "";
            form[field].isValid = false;
        }else{
			form[field].isValid = true;
			form[field].error = "";
        }

        this.setState({form});
        return (error === "");
    }



    onBlur = event => {
        this.validate(event.target.name, true);
    }      


    validateSubmit(){
        let isValidForm = true;
        for (var [, value] of Object.entries(this.state.form)) { 
			if(!value.isValid){
				isValidForm = false;
				break;
			}  
		}	
        this.setState({isValidForm: isValidForm});
	}


    render(){

		const { form, response } = this.state; 

		return (
			<div className="card card-login">


				<ul className="card-header">
					<li className="active">{this.props.t('login.title')}</li>
					<li><Link to="/register">{this.props.t("register.title")}</Link></li>
				</ul>


				<div className="card-body">
					{(response.status >= 400 &&  response.status<500 ) &&
						<div className="resultBox resultBox_ok">
							{parse(this.props.t("login.req.okacces"))}
						</div>
					}
					{(response.status >= 400 &&  response.status<500 ) &&
						<p className="text">{parse(this.props.t(response.message))}</p>
					}

					{(response.status === 500 || response.status === "") &&
					<form onSubmit={this.onSubmit} autoComplete="off">
					
						{response.status === 500 &&
							<div className="resultBox resultBox_error">
								{parse(this.props.t(response.message))}
							</div>
						}

						
						<TextField  
							name="email"
							label={this.props.t('login.form.username')}
							variant="outlined" 
							onChange={this.onChange}
							onBlur={this.onBlur}
							error={(form.email.error!=='')}
							helperText={(form.email.error!=='')? this.props.t(form.email.error) : false}
							required
							fullWidth
							margin="dense"
							className={"MuiFormControl-root MuiTextField-root MuiFormControl-marginDense MuiFormControl-fullWidth " + ((form.email.isValid)? "isvalid" : "")}
						/>



						<TextField  
							name="password"
							type="password"
							label={this.props.t('login.form.password')}
							variant="outlined" 
							onChange={this.onChange}
							onBlur={this.onBlur}
							error={(form.password.error!=='')}
							helperText={(form.password.error!=='')? this.props.t(form.password.error) : false}
							required
							fullWidth
							margin="dense"
							className={"MuiFormControl-root MuiTextField-root MuiFormControl-marginDense MuiFormControl-fullWidth " + ((form.password.isValid)? "isvalid" : "")}
						/>

						
						<div className="form-group row">
							<div className="col-sm-12">
								<input type="submit"
										className="btn float-right login_btn"
										value={this.props.t('login.submit')}
								/>
							</div>
						</div>
					</form>
					}
	
				
					{(response.status === 500 || response.status === "") &&
						
						<ul className="links">
                        <li><Link to="/remember">{this.props.t("login.link.toremember")}</Link></li>
                   		 </ul>
					}

					{response.status === 412 &&
							<Link to={response.params.href}>
								<button type="button" className="btn">{this.props.t("login.button.resendmail")}</button>
							</Link>

					}

					{response.status === 403 &&
							<Link to={response.params.href}>
								<button type="button" className="btn">{this.props.t("login.button.reactivate")}</button>
							</Link>
					}
				</div>
			</div>

        );
    }
}

export default withTranslation()(Login);