import React from 'react';
import { withTranslation } from 'react-i18next';
import {Link} from 'react-router-dom';
import countries from './countries.json';
import typeCompany from './typeCompany.json';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Services from '../../../service/service';
import parse from 'html-react-parser';
import './style.scss';


class Register extends React.Component {

    state = {
        value: '',
        suggestions: [],
        response: {
            status:"",
            message:""
        },
        captcha: true,
        form:{
            firstName:{value:"", error:"",isValid:false},
            lastName:{value:"", error:"",isValid:false},
            email:{value:"", error:"",isValid:false},
            phone:{value:"", error:"",isValid:false},
            country:{value:"", error:"",isValid:false},
            password:{value:"", error:"",isValid:false},
            //repeatPassword:{value:"", error:"",isValid:false},
            company:{value:"", error:"",isValid:false},
            typeCompany:{value:"", error:"",isValid:false},
            positionCompany:{value:"", error:"",isValid:false},
            comment:{value:"", error:"",isValid:true}
        },
        submit: false,

    }


    constructor(){
        super();
        this.countries = countries;
        this.typeCompany = typeCompany;
        this.services = Services;
    }
    onSubmit = async event => {
        this.props.spinner(true);
        const isSubmited = this.validateSubmit();
 
        event.preventDefault();
        if(isSubmited && this.state.captcha){
            let response = {};

            let f = Object.assign({}, this.state.form); 
     

            var md5 = require('md5');
            let res = await this.services.post('_users/presignup', {s:md5(f.email)});
            if(res.status){
                
                f['t'] = res.data.t;
                f['s'] = res.data.s;
                f['v'] = md5(f.email + res.data.s);

                res = await this.services.post('_users/signup', f);
                response = {status:((res.status)? "ok": "error"), message: res.data}; 

            }else{
                response = {status: "error", message:"restore.req.tokennovalid"};
            }

            this.setState({response: response});
        }
        this.props.spinner(false);
    }
    
    onChangeCountry = (event, newValue, method)=>{
        
        let new_state = Object.assign({}, this.state); 
        let f = new_state.form;
        f["country"] = {value:((newValue || {}).name || "") , error:"", isValid:false};
        this.setState({form: f});
        this.validate("country", false);
    }
    onChangeTypeCompany = (event, newValue, method)=>{
        
        let new_state = Object.assign({}, this.state); 
        let f = new_state.form;
        f["typeCompany"] = {value: ((newValue || {}).name || "")  , error:"", isValid:false};
        this.setState({form: f});
        this.validate("typeCompany", false);
    }
    
    onChangeC = () => {
        this.setState({captcha: false}); 
    }

    onChange = event => {
        
        let new_state = Object.assign({}, this.state); 
        let f = new_state.form;
        f[event.target.name] = {value:event.target.value, error:"", isValid:false};
        this.setState({form: f, submit: false});
        this.validate(event.target.name, false);
    };

    onBlur = event => {
        this.validate(event.target.name, true);     
    }
    onBlurCountry = event => {
        this.validate("country", true);     
    }
    onBlurTypeCompany = event => {
        this.validate("typeCompany", true);     
    }
    

    validateSubmit(){
        // Filtramos los campos del formulario por los no validos
        // si es diferente a 0 no cumple validación       
        return (Object.values(this.state.form).filter(obj=>(!(obj.isValid))).length === 0);
        
    }

    validate = (field,refresh, sub=false) => {
        const value = this.state.form[field].value;
        let error = "";
        switch(field){
            case "email":
                const email = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/;
                error = (!email.test(value))? "register.error.email" : "";
                break;
            case "phone":
                //const phoneno = /^\+?([0-9]{2})?\)?[-. ]?([0-9]{3})[-. ]?([0-9]{3})[-. ]?([0-9]{3})$/;
                //error = (!phoneno.test(value))? "register.error.phone" : "";
                error = (value.length < 6)? "register.error.phone" : "";
                break;
            case "password":
                error = (value.length < 6)? "register.error.password" : "";
            //    if(!sub) this.validate('repeatPassword', refresh, true);
                break;
           /* case "repeatPassword":
                error = (value !== this.state.form.password.value)? "register.error.repeatPassword" : "";
                if(!sub) this.validate('password', refresh, true);
                break;
            */
             case "comment":
               error = ""; //(value.length < 10)? "register.error.textarea" : "";
                break;
            case "country":
                error = (value.length < 2)? "register.error.country" : "";
                break;
            case "typeCompany":
                error = (value.length < 2)? "register.error.select" : "";
                break;
            default:
                error = (value.length < 2)? "register.error.text" : "";
        }

        let new_state = Object.assign({}, this.state); 
        let form = new_state.form;

        form[field]["error"] = (error && refresh)? error : "";
        form[field]["isValid"] = !(error);
        
        this.setState({form: form});
        return (error === "");
    }

      
    render(){

        const currentLng = localStorage.getItem('i18nextLng') || 'en';
        const _countries = this.countries[currentLng];
        const _typeCompany = this.typeCompany[currentLng];
              
        const {form,  response } = this.state;

        return (
                <div className="card card-signup">

                    <ul className="card-header">
                        <li><Link to="/login">{this.props.t("login.title")}</Link></li>
                        <li className="active">{this.props.t('register.title')}</li>
                    </ul>

                    {response.status==="ok" &&
                    <div className="card-body card-body-little">
                        <div className="resultBox resultBox_ok">
                            {parse(this.props.t(response.message))}
                        </div>
                        <p className="text">{parse(this.props.t("register.textok"))}</p>
                    </div>
                    }

                    {(response.status==="error" || response.status==="") && 
                    <div className="card-body">
                        {response.status==="error" &&
                        <div className="resultBox resultBox_error">
                            {parse(this.props.t(response.message))}
                        </div>
                        }
                        
                        <form autoComplete="off" onSubmit={this.onSubmit}>                            
                            <div className="form-group row">
                                <div className="col-md-6">
                                    <TextField  
                                        name="firstName"
                                        label={this.props.t('register.form.firstname')}
                                        variant="outlined" 
                                        onChange={this.onChange}
                                        onBlur={this.onBlur}
                                        error={(form.firstName.error!=='')}
                                        helperText={(form.firstName.error!=='')? this.props.t(form.firstName.error) : false}
                                        required
                                        fullWidth
                                        margin="dense"
                                        className={"MuiFormControl-root MuiTextField-root MuiFormControl-marginDense MuiFormControl-fullWidth " + ((form.firstName.isValid)? "isvalid" : "")}
                                    />
                                </div>    
                                <div className="col-md-6">
                                    <TextField  
                                        name="lastName"
                                        label={this.props.t('register.form.lastname')}
                                        variant="outlined" 
                                        onChange={this.onChange}
                                        onBlur={this.onBlur}
                                        error={(form.lastName.error!=='')}
                                        helperText={(form.lastName.error!=='')? this.props.t(form.lastName.error) : false}
                                        required
                                        fullWidth
                                        margin="dense"
                                        className={"MuiFormControl-root MuiTextField-root MuiFormControl-marginDense MuiFormControl-fullWidth " + ((form.lastName.isValid)? "isvalid" : "")}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-md-6">
                                    <TextField  
                                        name="phone"
                                        label={this.props.t('register.form.phone')}
                                        variant="outlined" 
                                        onChange={this.onChange}
                                        onBlur={this.onBlur}
                                        error={(form.phone.error!=='')}
                                        helperText={(form.phone.error!=='')? this.props.t(form.phone.error) : false}
                                        required
                                        fullWidth
                                        margin="dense"
                                        className={"MuiFormControl-root MuiTextField-root MuiFormControl-marginDense MuiFormControl-fullWidth " + ((form.phone.isValid)? "isvalid" : "")}
                                    />
                                </div>

                                <div className="col-md-6">
                                    <Autocomplete
                                        name="country"
                                        onChange={this.onChangeCountry}
                                        onBlur={this.onBlurCountry}
                                        options={_countries}
                                        autoHighlight
                                        autoSelect
                                        autoComplete
                                        getOptionLabel={(option) => option.name}
                                        renderOption={(option) => (
                                            <React.Fragment>{option.name}</React.Fragment>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                            {...params}
                                            label={this.props.t('register.form.choosecountry')}
                                            variant="outlined"
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'new-password', // disable autocomplete and autofill
                                            }}
                                     
                                            margin="dense"
                                            required
                                            fullWidth
                                 
                                            error={(form.country.error!=='')}
                                            helperText={(form.country.error!=='')? this.props.t(form.country.error) : false}
                                            className={"MuiFormControl-root MuiTextField-root MuiFormControl-marginDense MuiFormControl-fullWidth " + ((form.country.isValid)? "isvalid" : "")}
                                            />
                                        )}
                                    />
                                    
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="col-md-6">           
                                    <TextField  
                                        name="email"
                                        label={this.props.t('register.form.email')}
                                        variant="outlined" 
                                        onChange={this.onChange}
                                        onBlur={this.onBlur}
                                        error={(form.email.error!=='')}
                                        helperText={(form.email.error!=='')? this.props.t(form.email.error) : false}
                                        required
                                        fullWidth
                                        margin="dense"
                                        className={"MuiFormControl-root MuiTextField-root MuiFormControl-marginDense MuiFormControl-fullWidth " + ((form.email.isValid)? "isvalid" : "")}
                                    />
                                </div>

                                <div className="col-md-6">
                                    <TextField  
                                        name="password"
                                        type="password" 
                                        label={this.props.t('register.form.password')}
                                        variant="outlined" 
                                        onChange={this.onChange}
                                        onBlur={this.onBlur}
                                        error={(form.password.error!=='')}
                                        helperText={(form.password.error!=='')? this.props.t(form.password.error) : false}
                                        required
                                        fullWidth
                                        margin="dense"
                                        className={"MuiFormControl-root MuiTextField-root MuiFormControl-marginDense MuiFormControl-fullWidth " + ((form.password.isValid)? "isvalid" : "")}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-md-4">
                                    <TextField  
                                        name="company"
                                        label={this.props.t('register.form.company')}
                                        variant="outlined" 
                                        onChange={this.onChange}
                                        onBlur={this.onBlur}
                                        error={(form.company.error!=='')}
                                        helperText={(form.company.error!=='')? this.props.t(form.company.error) : false}
                                        required
                                        fullWidth
                                        margin="dense"
                                        className={"MuiFormControl-root MuiTextField-root MuiFormControl-marginDense MuiFormControl-fullWidth " + ((form.company.isValid)? "isvalid" : "")}
                                    />
                                </div>
                    
                                <div className="col-md-4">
                                    <Autocomplete    
                                        name="typeCompany"
                                        options={_typeCompany}
                                        onChange={this.onChangeTypeCompany}
                                        onBlur={this.onBlurTypeCompany}
                                        autoHighlight
                                        autoSelect
                                        autoComplete
                                        getOptionLabel={(option) => option.name}
                                        renderOption={(option) => (
                                            <React.Fragment>{option.name}</React.Fragment>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                            {...params}
                                            label={this.props.t('register.form.choosetypeCompany')}
                                            variant="outlined"
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'new-password', // disable autocomplete and autofill
                                            }} 
                                            margin="dense"
                                            required
                                            fullWidth
                                            error={(form.typeCompany.error!=='')}
                                            helperText={(form.typeCompany.error!=='')? this.props.t(form.typeCompany.error) : false}
                                            className={"MuiFormControl-root MuiTextField-root MuiFormControl-marginDense MuiFormControl-fullWidth " + ((form.typeCompany.isValid)? "isvalid" : "")}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <TextField  
                                        name="positionCompany"
                                        label={this.props.t('register.form.positioncompany')}
                                        variant="outlined" 
                                        onChange={this.onChange}
                                        onBlur={this.onBlur}
                                        error={(form.positionCompany.error!=='')}
                                        helperText={(form.positionCompany.error!=='')? this.props.t(form.positionCompany.error) : false}
                                        required
                                        fullWidth
                                        margin="dense"
                                        className={"MuiFormControl-root MuiTextField-root MuiFormControl-marginDense MuiFormControl-fullWidth " + ((form.positionCompany.isValid)? "isvalid" : "")}
                                    />
                                </div>
                            </div>
                
                            <div className="form-group row">
                                <div className="col-md-12">
                                    <TextField  
                                        name="comment"
                                        label={this.props.t('register.form.comment')}
                                        variant="outlined" 
                                        onChange={this.onChange}
                                        onBlur={this.onBlur}
                                        error={(form.comment.error!=='')}
                                        helperText={(form.comment.error!=='')? this.props.t(form.comment.error) : false}
                                        required={false}
                                        fullWidth
                                        margin="dense"
                                        multiline
                                        rows={2}
                                        className={"MuiFormControl-root MuiTextField-root MuiFormControl-marginDense MuiFormControl-fullWidth " + ((form.comment.isValid)? "isvalid" : "")}
                                    />
                                </div>
                            </div>
                            <div className="ads">
                                <input id="inputAdress" 
                                            name="Adress" 
                                            value=""
                                            type="text" 
                                            className="form-control"
                                            onChange={this.onChangeC}
                                            placeholder={this.props.t("register.form.address")} />
                            </div>
                            <div className="form-group center">
                                        <input 
                                            type="submit" 
                                            value={this.props.t("register.form.submit")} 
                                            className="btn login_btn" />
                            </div>
                        </form>    
                    </div>
                    }
                </div>
            );
    }
}

export default withTranslation()(Register);