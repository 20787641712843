import React from 'react';
import Header from '../common/header/header';
import Ourstanding from './outstanding/outstanding';
import OurstandingCarrousel from './outstanding/outstandingCarrousel';
import Filters from './filters/filters';
import Search from './search/search';
import Favorites from './favorites/favorites';
import Carousels from './carousels/carousels';
import Footer from '../common/footer/footer';
import Spinner from '../common/spinner/spinner';

import './style.scss';

export default class List extends React.Component {

    constructor(props){
        super(props);
        this.props = props;
        this.state={ 
            windowsType: this.windowsType(window.innerWidth),
            spinner: false,
            filters:{}, 
            currentLng: localStorage.getItem('i18nextLng')
        }      
    }
    spinner = (value) => {
        this.setState({ spinner: value });
    }
    

    onfChange = async (options,name) => {
        let new_state = Object.assign({}, this.state); 
        let filters = new_state.filters;
        if(name === "ALL"){
            filters={};
        }else{
            filters[name] = options;
        }
        if(this.mounted) {this.setState({ filters });}  
    }

    onhChange = async (value) => {
        if(this.mounted) {this.setState({ currentLng: value });}  
    }

    componentWillUnmount(){
        this.mounted = false;
        window.removeEventListener('resize', this.updateDimensions);
    }
    
    componentDidMount(){
        this.mounted = true;
        window.addEventListener('resize', this.updateDimensions);
    }

    shouldComponentUpdate(next_props, next_state) {
        return (next_props !== next_state);
    }


    windowsType = width =>{
        return (width > 768)? 'desktop' : 'mobile';
    }
    
    updateDimensions = () => {
        const windowsType = this.windowsType(window.innerWidth);
        if(this.mounted && windowsType !== this.state.windowsType){
            this.mounted = false;
            this.setState({windowsType});
        }
    };

    render(){
        const {currentLng, windowsType} = this.state;
        const  sizeFilters = Object.keys(this.state.filters).length;
        
        this.mounted = true;
        return (
            <div className="fullcontainer" id="ListContainer">
                <Header  onhChange={this.onhChange} sticky={true} {...this.props} />

                {(this.props.defaultView === 'list' && sizeFilters === 0 && windowsType === 'desktop') &&  
                    <Ourstanding   /> 
                }
                {(this.props.defaultView === 'list' && sizeFilters === 0 && windowsType === 'mobile') &&  
                    <OurstandingCarrousel   /> 
                }
                {((this.props.defaultView !== 'favorites')) &&
                <Filters currentLng={currentLng} onfChange={this.onfChange}  filter={this.state.filters} />
                }
                {((this.props.defaultView === 'search') || (this.props.defaultView === 'list' && windowsType === 'mobile')) &&
                    <Search  search={this.props.match.params.search} filter={this.state.filters} />
                }

                {((this.props.defaultView === 'favorites')) &&
                    <Favorites  search={this.props.match.params.search} filter={this.state.filters} />
                }

                {(this.props.defaultView === 'list' && windowsType === 'desktop') &&
                    <Carousels filter={this.state.filters} />
                }
                <Footer />
                <Spinner show={this.state.spinner}/>
            </div>
        );
    }
}