import React from 'react';
import ReactDOM from 'react-dom';
import './i18n/i18n';
import Auth from './Auth/auth';
import {BrowserRouter, Route, Switch, Redirect} from 'react-router-dom';
import Home from './components/home/home';
import Error from './components/error/error';
import List from './components/list/list';
import Static from './components/static/static';
import Detail from './components/detail/detail';
import MyAccount from './components/myaccount/myaccount';
import PropTypes from 'prop-types'

import { Provider } from 'react-redux';
import store from './store';



const PrivateRoute = ({ component: Component, view:View, ...rest }) => (
  <Route {...rest} render={(props) => (
    Auth.isAuth(true) === true
      ? <Component {...props} defaultView={View} />
      : <Redirect to='/login' />
  )} />
);

const PublicRoute = ({ component: Component, view:View, ...rest }) => (
  <Route {...rest} render={(props) => (
    Auth.isAuth(false) !== true
      ? <Component {...props} defaultView={View} />
      : <Redirect to='/vod' />
  )} />
);

const App = ({store}) => (
  <Provider store={store}>
  <BrowserRouter>
      <Switch>
        <PrivateRoute  path="/vod/search/:search" component={List}  view="search" />
        <PrivateRoute  path="/vod/video/:id" component={Detail} />
        <PrivateRoute  exact path="/vod/favorites" component={List}  view="favorites" />
        <PrivateRoute  exact path="/vod/myaccount" component={MyAccount} />
        <PrivateRoute  exact path="/vod" component={List}  view="list" />



        <PublicRoute  path="/register" component={Home} view="register" />
        
        <PublicRoute  path="/remember" component={Home} view="remember" />
        <PublicRoute  path="/restorepass/:token/:time/:id/:lng" component={Home} view="restorepass" />
        
        <PublicRoute  path="/invitation/:token/:time/:id/:lng" component={Home} view="invitation" />
        <PublicRoute  path="/resentvalidationemail/:token/:time/:id/:lng" component={Home} view="resentvalidationemail" />
        <PublicRoute  path="/reactivate/:token/:time/:id/:lng" component={Home} view="reactivate" />
        <PublicRoute  path="/validateemail/:token/:time/:id/:lng" component={Home} view="validateemail" />

        <Route  path="/legalnotice" render={(props)=><Static  url="legalnotice" />} />
        <Route  path="/cookiespolicy" render={(props)=><Static  url="cookiespolicy" />} />
        <Route  path="/privacypolicy" render={(props)=><Static  url="privacypolicy" />} />

        <PublicRoute  path="/login" component={Home} view="login" />
        <PublicRoute  exact path="/" component={Home} view="login" />
        <Route  render={(props)=><Error />} />
      </Switch>
  </BrowserRouter>  
  </Provider>
);

App.propTypes = {
  store: PropTypes.object.isRequired
}

ReactDOM.render(<App store={store} />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
