import React from 'react';
import { Translation } from 'react-i18next';
import {Link} from 'react-router-dom';
import Like from '../like/like';

import './style.scss';
export default class Video extends React.Component {

    constructor(props){
        super(props);
        this.props = props;
        this.state = {
            visible: false
        };
    }

    trasformSecondsToTime(d){
        d = d || 0;
        var sec_num = parseInt(d, 10);
        var hours   = Math.floor(sec_num / 3600);
        var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
        var seconds = sec_num - (hours * 3600) - (minutes * 60);
    
        if (hours < 10) {hours= "0" + hours;}
        //hours  = (hours  !== "00")? hours +":" : "";
        if (minutes < 10) {minutes = "0"+minutes;}
        if (seconds < 10) {seconds = "0"+seconds;}
        return hours + ':' + minutes + ':' + seconds;
    }

    trasformDate(d){
        let arr = d.split(/[-:T+]+/);
        return arr[2]+"/"+arr[1]+"/"+arr[0]; //+" "+arr[3]+":"+arr[4];
    }

    showDescription(text, site){
        // stripTags
        text = text.replace(/<\/?[^>]+(>|$)/g, "");
        let maxLength = (site === 'insite')? 300 : 300;
        // limit long text
        let len = text.length;
        if(len>maxLength){
            text=text.substring(0, maxLength) + "..";

        }
        return text;
    }

    handleClickMoreInfo = event => {
        this.setState({visible: !this.state.visible});
    }

    render(){
        let titleSource, descriptionSource;
        if(localStorage.getItem('i18nextLng')==='es'){
            titleSource = "titesp";
            descriptionSource = "descesp";
        }else{
            titleSource = "titeng";
            descriptionSource = "desceng";
        }

        let vid =  this.props.video;
        if(vid){
           // let vidDate = new Date(vid["_source"]["created_time"]);
            //var dateNow = new Date();
            //dateNow.setDate(dateNow.getDate() -60);
            //let isNew = dateNow<vidDate;
            let img = "";
            if(vid._source.imagePanelRadio === 'inlink'){
                img = (((vid._source.image || [])[0] || {}).assetSrc) || false;
                img = (img)?  process.env.REACT_APP_APIURI + img : "";
            }else{
                console.log(vid._source);
                img = ((vid._source.externalImage || [])[0] || {}).assetSrc || "";
            }
           

            return (
                <article className={this.props.textposition} >
                    <Link to={"/vod/video/"+vid["_id"]}>
                        <figure className={this.props.textposition}>

                            <div className="overlay"></div>                           
                            {img&&
                            <img alt={vid["_source"][titleSource]} title={vid["_source"][titleSource]} 
                                src={img}  />
                            }
                            <div className="description-insite">
                                {this.showDescription(vid["_source"][descriptionSource], this.props.textposition)}
              
                                <Translation>{t =>
                                <div className="playtext">{t("general.playvideo")}</div>
                                }</Translation>
                            </div>
                            {this.props.textposition === "insite" &&
                                <figcaption>
                                    <h4>{vid["_source"][titleSource]}</h4>
                                    <span className="time">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="13.239" height="13.239" viewBox="0 0 13.239 13.239">
                                            <g id="Grupo_113" data-name="Grupo 113" transform="translate(-59.995 319.972)">
                                                <path id="Trazado_210" data-name="Trazado 210" d="M119.058-273.409H116.4v-2.66a.715.715,0,0,0-.7-.713h-.037a.714.714,0,0,0-.695.713v3.375a.715.715,0,0,0,.713.714h3.375a.715.715,0,0,0,.713-.714A.715.715,0,0,0,119.058-273.409Z" transform="translate(-50.215 -39.45)" fill="#fff"/>
                                                <path id="Trazado_211" data-name="Trazado 211" d="M73.142-312.26c.011-.064.02-.128.029-.193.005-.039.01-.078.015-.117.007-.059.013-.117.019-.176s.008-.106.012-.159.007-.093.009-.14c0-.1.008-.206.008-.309a6.629,6.629,0,0,0-5.4-6.5,6.618,6.618,0,0,0-1.216-.114,6.614,6.614,0,0,0-1.458.164,6.629,6.629,0,0,0-5.162,6.455h0c0,.089,0,.18.007.28a6.63,6.63,0,0,0,5.486,6.241h0l.039.006.182.027.128.016.168.018.167.013.134.009c.1,0,.206.008.309.008h0c.088,0,.179,0,.278-.007a6.629,6.629,0,0,0,6.242-5.486h0C73.139-312.237,73.14-312.249,73.142-312.26Zm-5.718,4.035-.078.011-.16.02-.089.009-.162.012-.078,0c-.08,0-.161.006-.241.006a5.19,5.19,0,0,1-1.058-.109h0a5.206,5.206,0,0,1-4.071-4.278c0-.021-.006-.042-.009-.063-.008-.058-.016-.116-.022-.175,0-.027-.006-.054-.008-.081q-.008-.085-.013-.17c0-.025,0-.049,0-.073,0-.081-.006-.161-.006-.243a5.2,5.2,0,0,1,5.192-5.191,5.184,5.184,0,0,1,4.076,1.982,5.165,5.165,0,0,1,1.115,3.209,5.2,5.2,0,0,1-.109,1.058h0A5.205,5.205,0,0,1,67.424-308.224Z" transform="translate(0 0)" fill="#fff"/>
                                            </g>
                                        </svg>
                                        {this.trasformSecondsToTime(vid["_source"]["duration"])}
                                        <Like id={vid["_id"]} />
                                    </span>
                                 </figcaption>
                            }
                        </figure>
                        {this.props.textposition === "outsite" &&
                        <figcaption>
                                <h4>{vid["_source"][titleSource]}</h4>
                                <span className="time">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="13.239" height="13.239" viewBox="0 0 13.239 13.239">
                                        <g id="Grupo_113" data-name="Grupo 113" transform="translate(-59.995 319.972)">
                                            <path id="Trazado_210" data-name="Trazado 210" d="M119.058-273.409H116.4v-2.66a.715.715,0,0,0-.7-.713h-.037a.714.714,0,0,0-.695.713v3.375a.715.715,0,0,0,.713.714h3.375a.715.715,0,0,0,.713-.714A.715.715,0,0,0,119.058-273.409Z" transform="translate(-50.215 -39.45)" fill="#00B3FF"/>
                                            <path id="Trazado_211" data-name="Trazado 211" d="M73.142-312.26c.011-.064.02-.128.029-.193.005-.039.01-.078.015-.117.007-.059.013-.117.019-.176s.008-.106.012-.159.007-.093.009-.14c0-.1.008-.206.008-.309a6.629,6.629,0,0,0-5.4-6.5,6.618,6.618,0,0,0-1.216-.114,6.614,6.614,0,0,0-1.458.164,6.629,6.629,0,0,0-5.162,6.455h0c0,.089,0,.18.007.28a6.63,6.63,0,0,0,5.486,6.241h0l.039.006.182.027.128.016.168.018.167.013.134.009c.1,0,.206.008.309.008h0c.088,0,.179,0,.278-.007a6.629,6.629,0,0,0,6.242-5.486h0C73.139-312.237,73.14-312.249,73.142-312.26Zm-5.718,4.035-.078.011-.16.02-.089.009-.162.012-.078,0c-.08,0-.161.006-.241.006a5.19,5.19,0,0,1-1.058-.109h0a5.206,5.206,0,0,1-4.071-4.278c0-.021-.006-.042-.009-.063-.008-.058-.016-.116-.022-.175,0-.027-.006-.054-.008-.081q-.008-.085-.013-.17c0-.025,0-.049,0-.073,0-.081-.006-.161-.006-.243a5.2,5.2,0,0,1,5.192-5.191,5.184,5.184,0,0,1,4.076,1.982,5.165,5.165,0,0,1,1.115,3.209,5.2,5.2,0,0,1-.109,1.058h0A5.205,5.205,0,0,1,67.424-308.224Z" transform="translate(0 0)" fill="#00B3FF"/>
                                        </g>
                                    </svg>
                                    {this.trasformSecondsToTime(vid["_source"]["duration"])}
                                    <Like id={vid["_id"]} />
                                </span>
                        </figcaption>
                        }
                    </Link>
                    {this.props.textposition === "outsite" &&
                        <div className={"moreInformation "+ ((this.state.visible)? 'show': '')} onClick={this.handleClickMoreInfo}>
                            <svg className="arrow active" xmlns="http://www.w3.org/2000/svg" width="16.779" height="27.901" viewBox="0 0 16.779 27.901"><g id="Grupo_118" data-name="Grupo 118" transform="translate(13.95 2.828) rotate(90)"><line id="Línea_4" data-name="Línea 4" x2="11.122" y2="11.122" transform="translate(0 0)" fill="none" stroke="#434343" stroke-linecap="round" stroke-width="4"></line><line id="Línea_5" data-name="Línea 5" x1="11.122" y2="11.122" transform="translate(11.122 0)" fill="none" stroke="#434343" stroke-linecap="round" stroke-width="4"></line></g></svg>
                            <Translation>{t =>
                                <span>{t("general.moreinfo")}</span>
                                }</Translation>
                            <div className="description-outsite">
                                {this.showDescription(vid["_source"][descriptionSource], this.props.textposition)}
                            </div>
                         </div>
                    }
                </article>);
        }else{
            return ( <article><figure></figure></article>);
        }
        
    }
}
/*
 <span className="time"><i className="fas fa-calendar"></i> 
 {this.trasformDate(vid["_source"]["created_time"])}</span>
*/