import React from 'react';
import { withTranslation } from 'react-i18next';
import {Link} from 'react-router-dom';
import Services from '../../../service/service';
import TextField from '@material-ui/core/TextField';
import parse from 'html-react-parser';

import './style.scss';
class Remember extends React.Component {

    state ={
        isValidEmail: null,
        email: "",
        messageError:"",
        response: {
            status:"",
            message:""
        },
    }


    constructor(){
        super();
        this.services = Services;
    }

    onSubmit = async event => {
        this.props.spinner(true);
        event.preventDefault();
        let response = {};
        let res = await this.services.post('_users/rememberpass', {email: this.state.email});
        
        response = {status:((res.status)? "ok": "error"), message: res.data};
        this.setState({response: response});
        this.props.spinner(false);
    }

    onChange = event => {
        this.setState({email: event.target.value, isValidEmail: null});        
        this.validate(false);
    };


    validate = (refresh) => {
        const value = this.state.email;       
        const email = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/;
        let error = (!email.test(value))? "register.error.email" : "";
        if(refresh){
            this.setState({isValidEmail: (error===""), messageError:error});
        }else{
            this.setState({isValidEmail: (error===""), messageError:""});
        }
    }


    onBlur = () => {
        this.validate(true);
    }  

  
    render(){

        const { isValidEmail, messageError, response } = this.state; 

        return (
            <div className="card card-login" >

                <ul className="card-header">
					<li className="active">{this.props.t('remember.title')}</li>
				</ul>

                {response.status==="ok" && 
                <div className="card-body">             
                    <div className="resultBox resultBox_ok">
                        {parse(this.props.t(response.message))}
                    </div>
                    <p className="text">{parse(this.props.t("remember.textok"))}</p>

                    <ul className="links">
                        <li>{this.props.t("register.link.tologin")}&nbsp;
                        <Link to="/login">{this.props.t("login.title")}</Link></li>
                    </ul>
                </div>
                }

                {(response.status==="error" || response.status==="") && 
                <div className="card-body">
                    <p className="text">{parse(this.props.t("remember.text"))}</p>
                   
                    {response.status ==="error" &&
                        <div className="resultBox resultBox_error">
                            {parse(this.props.t(response.message))}
                        </div>
                    }

                    <form autoComplete="off" onSubmit={this.onSubmit}>

                        <TextField  
							name="email"
							label={this.props.t('register.form.email')}
							variant="outlined" 
							onChange={this.onChange}
							onBlur={this.onBlur}
							error={(messageError!=='')}
							helperText={(messageError!=='')? this.props.t(messageError) : false}
							required
							fullWidth
							margin="dense"
							className={"MuiFormControl-root MuiTextField-root MuiFormControl-marginDense MuiFormControl-fullWidth " + ((isValidEmail)? "isvalid" : "")}
						/>

                        <div className="form-group row">
                        <div className="col-sm-12">
                                <input type="submit" 
                                    value={this.props.t("remember.form.submit")} 
                                    className="btn float-right login_btn"
                                />
                            </div>
                        </div>
                    </form>

                    <ul className="links">
                        <li> {this.props.t("login.link.toregister")}&nbsp;
                        <Link to="/register">{this.props.t("register.title")}</Link></li>
                        <li>{this.props.t("register.link.tologin")}&nbsp;
                        <Link to="/login">{this.props.t("login.title")}</Link></li>
                    </ul>

                </div>
                }
            </div>
        );
    }
}

export default withTranslation()(Remember);