import React from 'react';
import { withTranslation } from 'react-i18next';
import {Link} from 'react-router-dom';
import Services from '../../../service/service';
import TextField from '@material-ui/core/TextField';
import parse from 'html-react-parser';

import './style.scss';

class RestorePass extends React.Component {

    state ={
        isValidForm: false,
        form:{
			password: {value:"", isValid:false, error:""},
			repeatPassword: {value:"", isValid:false, error:""}
		},
		response: {
            status:"",
            message:""
        }
    }
	constructor(){
        super();
        this.services = Services;
    }
	async UNSAFE_componentWillMount(){
		this.props.spinner(true);
		this.props.i18n.changeLanguage((this.props.handle.lng==='es')? 'es':'en');
		let res = await this.services.post('_users/validatetoken', {
															t:this.props.handle.token,
															s:this.props.handle.id,
															d:this.props.handle.time
														});
		this.props.spinner(false);
        let response = (res.data===true)? {status:"form"} : {status:"error", message: "restore.req.tokennovalid"};
		this.setState({response: response});
	}

     onSubmit = async (event) => {
		this.props.spinner(true);
		event.preventDefault();

		let res = await this.services.post('_users/updatepass', {
			t:this.props.handle.token,
			s:this.props.handle.id,
			d:this.props.handle.time,
			password: this.state.form.password.value
		});
  
		let response = (res.status) ? {status:"ok",  message: "restore.req.ok"} 
										: {status:"error", message: "restore.req.ko"};
        
		this.setState({response: response});
		this.props.spinner(false);
    }

    onChange = event => {
        		
		let new_state = Object.assign({}, this.state); 
        let f = new_state.form;
    
        f[event.target.name] = {value:event.target.value, error:"", isValid:false};
		this.setState({form: f, isValidForm: false});  
        this.validate(event.target.name, false);     

    };


    validate = (field,refresh, sub=false) => {
        const value = this.state.form[field].value;
        let error = "";
        switch(field){
            case "password":
                error = (value.length < 6)? "register.error.password" : "";
                if(!sub) this.validate('repeatPassword', refresh, true);
                break;
            case "repeatPassword":
                error = (value !== this.state.form.password.value)? "register.error.repeatPassword" : "";
                if(!sub) this.validate('password', refresh, true);
                break;
			default:

        }

        let new_state = Object.assign({}, this.state); 
        let form = new_state.form;

        if(error){
            form[field].error = (refresh)? error : "";
            form[field].isValid = false;
        }else{
			form[field].isValid = true;
			form[field].error = "";
        }

        this.setState({form: form});
        return (error === "");
    }


    onBlur = event => {
        this.validate(event.target.name, true, false);
    }      


    validateSubmit(){
        let isValidForm = true;
        for (var [, value] of Object.entries(this.state.form)) { 
			if(!value.isValid){
				isValidForm = false;
				break;
			}  
		}
		
        this.setState({isValidForm: isValidForm});
	}
	

    render(){
		const { form, response } = this.state; 

		return (
			<div className="card card-login">
				{response.status && 
				<ul className="card-header">
					<li className="active">{this.props.t('restore.title')}</li>
				</ul>
				}
                {response.status==="ok" && 
                <div className="card-body">             
           
                       <div className="resultBox resultBox_ok">
                           {parse(this.props.t(response.message))}
                       </div>
                       <p className="text">{parse(this.props.t("restore.req.ok.message"))}</p>

					   <ul className="links">
                        <li>
                        <Link to="/login">{this.props.t("login.title")}</Link></li>
                    	</ul>
                </div>
                }

				{(response.status==="error" || response.status==="form" || response.status==="invalid") && 
				<div className="card-body">
					{response.status!=="invalid" &&
					<p className="text">{parse(this.props.t("restore.message"))}</p>		
					}
 					{(response.status ==="error" || response.status==="invalid") &&
                        <div className="resultBox resultBox_error">
                            {parse(this.props.t(response.message))}
                        </div>
                    }

					{response.status!=="invalid" &&
					<form onSubmit={this.onSubmit} autoComplete="off">

						<TextField  
							name="password"
							type="password"
							label={this.props.t('login.form.password')}
							variant="outlined" 
							onChange={this.onChange}
							onBlur={this.onBlur}
							error={(form.password.error!=='')}
							helperText={(form.password.error!=='')? this.props.t(form.password.error) : false}
							required
							fullWidth
							margin="dense"
							className={"MuiFormControl-root MuiTextField-root MuiFormControl-marginDense MuiFormControl-fullWidth " + ((form.password.isValid)? "isvalid" : "")}
						/>
			

						<TextField  
							name="repeatPassword"
							type="password"
							label={this.props.t('register.form.repeatpassword')}
							variant="outlined" 
							onChange={this.onChange}
							onBlur={this.onBlur}
							error={(form.repeatPassword.error!=='')}
							helperText={(form.repeatPassword.error!=='')? this.props.t(form.repeatPassword.error) : false}
							required
							fullWidth
							margin="dense"
							className={"MuiFormControl-root MuiTextField-root MuiFormControl-marginDense MuiFormControl-fullWidth " + ((form.repeatPassword.isValid)? "isvalid" : "")}
						/>


						<div className="form-group row">
							<div className="col-sm-12">
								<input type="submit"
									className="btn float-right login_btn"
									value={this.props.t('restore.submit')} 
								/>
							</div>
						</div>
					</form>
					}		
					<ul className="links">
                        <li>{this.props.t("register.link.tologin")}&nbsp;
                        <Link to="/login">{this.props.t("login.title")}</Link></li>
                    </ul>
				</div>
				}
			</div>
        );
    }
}

export default withTranslation()(RestorePass);
