import React from "react";

// By default no caret icon is supplied - any valid jsx markup will do
export const CaretIcon = () => (
  <svg
    className="caret-icon"
     xmlns="http://www.w3.org/2000/svg" width="16.779" height="27.901" viewBox="0 0 16.779 27.901">
    <g id="Grupo_118" data-name="Grupo 118" transform="translate(13.95 2.828) rotate(90)">
      <line id="Línea_4" data-name="Línea 4" x2="11.122" y2="11.122" transform="translate(0 0)" fill="none" stroke="#434343" strokeLinecap="round" strokeWidth="4"/>
      <line id="Línea_5" data-name="Línea 5" x1="11.122" y2="11.122" transform="translate(11.122 0)" fill="none" stroke="#434343" strokeLinecap="round" strokeWidth="4"/>
    </g>
  </svg>
);
