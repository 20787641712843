export const addStar = star =>{

    return {
        type: "ADD_STAR",
        playload: star
    }
}

export const delStar = star =>{

    return {
        type: "DEL_STAR",
        playload: star
    }
}

