import React from 'react';
import Video from '../../common/video/video';
import Services from '../../../service/service';
import './style.scss';

export default class ListComponent extends React.Component {

    constructor(props){
        super(props);
        this.props = props;
        this.services = Services;
        this.state={
            videos:[]
        }
    }

    getVideos = async filter =>{
      let f = JSON.parse(JSON.stringify(filter || {}));
      f[this.props.type]= [this.props.name];
      let videos = await this.services.post("videos/getVideos", {limit:20, filter: f});
      videos =  (videos.status)? videos.data : [];
      if(this.mounted) {this.setState({ videos });}     
    }

    componentWillUnmount(){
      this.mounted = false;
    }

    shouldComponentUpdate(next_props, next_state) {
      return (next_props !== next_state);
    }

    async componentDidMount(){
      this.mounted = true;
      await this.getVideos({});   
    }

    async UNSAFE_componentWillReceiveProps(next_props){
      await this.getVideos(next_props.filter);
    }
    
    render(){

        if(this.state.videos && this.state.videos.length>0){
        return (
          <div className="search container">
                 {this.state.message ? (
                     <div></div>
                 ):(<div className="row">
                    {this.state.videos.map((vid, i) => {                     
                        return (<div key={vid["_id"]} className="col-md-4"><Video textposition="outsite" video={vid || false} /></div>) ;
                    })}
                </div>)
                }
            </div>
        );
        }else{
          return(<span className="none"></span>)
        }
    }
}