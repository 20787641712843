import React from 'react';
import { withTranslation } from 'react-i18next';
import Header from '../common/header/header';
import Footer from '../common/footer/footer';
import {Link} from 'react-router-dom';
import parse from 'html-react-parser';

import './style.scss';
class Error extends React.Component {

    constructor(props){
        super(props);
        this.props = props;
    }

    render(){
        const random = (Math.random() < 0.5)? 0 : 1;

        return (



            <div className="fullcontainer" id="ErrorContainer">
            <Header  onhChange={this.onhChange} sticky={true} {...this.props} />


            
                <div className="container-flex">
                    <div className="row">
                        <div className="col-12">               
                            <h2 className={"bg" +  random}>{parse(this.props.t("error.title"))}</h2>
                        </div>
                    </div>
                </div>
            
                <div className="container">
                    <div className="row justify-content-md-center">
                        <div className="col-sm-7 text1">
                            {parse(this.props.t("error.text1"))}
                        </div>
                    </div>
                    <div className="row justify-content-md-center">
                        <div className="col-sm-7 text2">
                            {parse(this.props.t("error.text2"))}
                        </div>
                    </div>
                    <div className="row justify-content-md-center">
                        <div className="col-sm-7 text3">
                            <Link to="/">
                                <button type="button" className="btn">{this.props.t("error.button")}</button>
                            </Link>
                        </div>
                    </div>
                </div>

            <Footer />
            </div>            
        );
    }
}

export default withTranslation()(Error);