import React from 'react';
import { withTranslation } from 'react-i18next';
import {Link} from 'react-router-dom';
import Services from '../../../service/service';
import parse from 'html-react-parser';

import './style.scss';

class ActionServer extends React.Component {

    state ={
		response: {
            status:"",
            message:""
        }
    }
	constructor(){
        super();
        this.services = Services;
    }
	async UNSAFE_componentWillMount(){

		this.props.spinner(true);
		this.props.i18n.changeLanguage((this.props.handle.lng==='es')? 'es':'en');
		
		let res = await this.services.post('_users/'+ this.props.action, {
															t:this.props.handle.token,
															s:this.props.handle.id,
															d:this.props.handle.time
														});
        let response = (res.status)? {status:"ok"} : {status:"error"};
		this.setState({response: response});
		this.props.spinner(false);
	}


    render(){

		const {response} = this.state;

		return (
			
			<div className="card card-login">

				<ul className="card-header">
					<li className="active">{this.props.t(this.props.action+'.title')}</li>
				</ul>
				{response.status !== "" &&	
				<div className="card-body">
					
					<div className={"resultBox resultBox_"+response.status}>
						{parse(this.props.t(this.props.action+"."+response.status))}
					</div>

					<p className="text">{parse(this.props.t(this.props.action + ".message" + response.status))}</p>


                    <ul className="links">
                        <li>{this.props.t("register.link.tologin")}&nbsp;
                        <Link to="/login">{this.props.t("login.title")}</Link></li>
                    </ul>
					
				</div>
	
				}


			</div>
		    
        );
    }
}

export default withTranslation()(ActionServer);