import React from 'react';
import Carousel from 'react-multi-carousel';
import Video from '../../common/video/video';
import Services from '../../../service/service';
import 'react-multi-carousel/lib/styles.css';
import './style.scss';

export default class CarouselComponent extends React.Component {

    constructor(props){
        super(props);
        this.props = props;
        this.services = Services;
        this.state={
            videos:[]
        }
    }

    getVideos = async filter =>{
      let f = JSON.parse(JSON.stringify(filter || {}));
      f[this.props.type]= [this.props.name];
      let videos = await this.services.post("videos/getVideos", {limit:20, filter: f});
      videos =  (videos.status)? videos.data : [];
      if(this.mounted) {this.setState({ videos });}     
    }

    componentWillUnmount(){
      this.mounted = false;
    }

    shouldComponentUpdate(next_props, next_state) {
      return (next_props !== next_state);
    }

    async componentDidMount(){
      this.mounted = true;
      await this.getVideos({});   
    }

    async UNSAFE_componentWillReceiveProps(next_props){
      await this.getVideos(next_props.filter);
    }
    
    render(){

        const responsive = {
            desktop: {breakpoint: { max: 3000, min: 1024 },items: 3, slidesToSlide: 1 },
            tablet: { breakpoint: { max: 1024, min: 768 }, items: 2, slidesToSlide: 1 },
            mobile: { breakpoint: { max: 768, min: 0 },items: 1, slidesToSlide: 1 }
          };
          

          const ButtonGroup = ({ next, previous, ...rest }) => {
            const {
                carouselState: { currentSlide, totalItems, slidesToShow }
            } = rest;

            return (
                <div className="carousel-button-group">
                    <button aria-label="Go to previous slide"
                        className={currentSlide === 0 ? "leftb disable" : "leftb"}
                        onClick={() => previous()}></button>
                    <button aria-label="Go to next slide" 
                        className={currentSlide >= totalItems - slidesToShow ? "rightb disable" : "rightb"}
                        onClick={() => next()}></button>
                </div>
            );
        };

        if(this.state.videos && this.state.videos.length>0){
        return (
          <div className={((this.props.bg)? "carrousel-item carrousel-row-bg" : "carrousel-item")}>
          <div className="container">
            <div className="carrousel-videos row">
                <div className="col-md-12 carrousel-row">
                  <h3>{this.props.label}</h3>
                  
                  <Carousel
                    key={"carro_"+ this.props.label}
                    additionalTransfrom={0}
                    autoPlaySpeed={3000}
                    centerMode={false}
                    className=""
                    containerClass="vsncarrousel"
                    arrows={false}
                    renderButtonGroupOutside={true}
                    customButtonGroup={<ButtonGroup
                                        next={this.props.next}
                                        previous={this.props.previous}
                                        rest={this.props.rest}
                                      />}
                    dotListClass=""
                    draggable
                    focusOnSelect={false}
                    infinite={false}
                    itemClass=""
                    keyBoardControl
                    minimumTouchDrag={80}
                    renderDotsOutside={false}
                    removeArrowOnDeviceType={["tablet", "mobile"]}
                    deviceType={this.props.deviceType}
                    responsive={responsive}
                    showDots={false}
                    sliderClass=""
                    slidesToSlide={1}
                    swipeable
                  >
                    {this.state.videos.map((vid, i) => <Video textposition="outsite" video={vid} key={vid._id} />)}
                  </Carousel>
                </div>
          </div>
          </div>
          </div>
        );
        }else{
          return(<span className="none"></span>)
        }
    }
}


/*
<Carousel
                  key={"carro_"+ this.props.label}
                  swipeable={false}
                  draggable={true}
                  showDots={false}
                  responsive={responsive}
                  ssr={true} // means to render carousel on server-side.
                  infinite={false}
                  autoPlay={false}
                  autoPlaySpeed={500}
                  keyBoardControl={true}
                  customTransition="all .5"
                  transitionDuration={0}
                  containerClass="carousel-container"
                  removeArrowOnDeviceType={["tablet", "mobile"]}
                  deviceType={this.props.deviceType}
                  dotListClass="custom-dot-list-style"
                  itemClass="carousel-item-padding-40-px"
                >
                */